export default {
  common: {
    title: 'TF Dashboard',
    logout: 'Logout',
    changePassword: 'Change Password',
  },
  affiliate: {
    commissionModel: {
      NORMAL: 'Nomal',
      SIMPLE: 'Simple'
    },
    level: {
      AFFILIATE: 'Affiliate',
      SUPER_AFFILIATE: 'Super Affiliate',
      MASTER_AFFILIATE: 'Master Affiliate',
      CHIEF_AFFILIATE: 'Chief Affiliate'
    },
    timeType: {
      MONTHLY: 'Monthly',
      WEEKLY: 'Weekly'
    },
    status: {
      APPLY: 'Apply',
      NORMAL: 'Normal',
      DISABLE: 'Disable'
    }
  },
  types: {
    NORMAL: 'NORMAL',
    TEMPORARY: 'TEMPORARY',
    PERMANENT: 'PERMANENT',
    gameViolation: 'Game Violation',
    memberRequest: 'Member Request',
    others: 'Others',
    TEST: 'TEST',
    AFFILIATE: 'AFFILIATE',
    OUTSIDE: 'OUTSIDE',
  },
  reportName: {
    Aff_Month_Report: 'Aff_Month_Report',
    Deposit_Record: 'Deposit_Record',
    Withdraw_Record: 'Withdraw_Record',
    Privilege_Record: 'Privilege_Record',
    Finance_Report: 'Finance_Report',
    Summary_Record: 'Summary_Record',
    Summary_Active_Record: 'Summary_Active_Record',
    Summary_Register_Record: 'Summary_Register_Record',
    Summary_Fdp_Record: 'Summary_Fdp_Record',
    Summary_Withdraw_Record: 'Summary_Withdraw_Record',
    Summary_Deposit_Record: 'Summary_Deposit_Record',
    Summary_Member_Record: 'Summary_Member_Record',
  },
  dateType: {
    depositDate: 'Deposit Date',
    finishDate: 'Finish Date',
    withdrawDate: 'Withdraw Date',
    paymentDate: 'Payment Date'
  },
  depositStatus: {
    SUCCESS: 'Success',
    SUPPLEMENT_SUCCESS: 'Supplement Success',
    CLOSED: 'Closed',
    PENDING: 'Pending'
  },
  withdrawStatus: {
    ALL: 'All',
    SUCCESS: 'Success',
    FAIL: 'Fail',
    APPLY: 'Applying',
    STEP_1: 'Under Review',
    STEP_2: 'To Be Paid',
    STEP_3: 'Payment On Going',
    AUTOPAY: 'Automatic Payment',
    PENDING: 'Suspend',
    REJECT: 'Reject',
    SENDING: 'Sending',
    WAITING_CALLBACK: 'Waiting Callback',
    PAYING: 'Paying'
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  editCheckedStatus: {
    0: '未审核',
    1: '已更改',
    2: '审核失败',
  },
  transfer: {
    status: {
      sending: 'SENDING',
      success: 'SUCCESS',
      fail: 'FAIL'
    },
    type: {
      deposit: 'DEPOSIT',
      withdraw: 'WITHDRAW'
    }
  },
  moneyChange: {
    type: {
      DEPOSIT: 'DEPOSIT',
      WITHDRAW: 'WITHDRAW',
      PROMO: 'PROMO',
      TRANSFER: 'TRANSFER',
      BET: 'BET',
      WITHDRAW_FAIL: 'WITHDRAW FAIL',
      ADJUST: 'ADJUST',
      PAYOUT: 'PAYOUT',
      ROLLBACK: 'ROLLBACK',
      PATCH: 'PATCH',
      BET_N_PAYOUT: 'BET AND PAYOUT',
      AFFILIATE_TRANSFER: 'AFFILIATE TRANSFER'
    },
    subType: {
      DEPOSIT: 'Wallet to Game',
      WITHDRAW: 'Game to Wallet',
      TRASNFER_IN: 'Transfer In',
      TRANSFER_OUT: 'Transfer Out',
      AFFILIATE_SETTLEMENT: 'Affiliate Settlement'
    }
  },
  status: {
    monthSummary: {
      UNCLEAR: 'Unclear',
      CLEARING: 'Clearing',
      CLEARED: 'Cleared'
    },
    settlement: {
      CHECKING: 'Checking',
      PAY: 'Pay',
      CLEARED: 'Cleared'
    },
    member: {
      NORMAL: 'Normal',
      FROZEN: 'Frozen'
    }
  },
  dashboard: {
    memberCount: 'Member Count',
    totalFirstDeposit: 'Total First Deposit',
    totalDeposit: 'Total Deposit',
    totalTransaction: 'No. of Transaction',
    totalTransfer: 'Total Transfer',
    totalWithdraw: 'Total Withdraw',
    totalWinLoss: 'Total Win/Loss',
    totalValidBet: 'Total Valid Bet',
    totalBonus: 'Total Bonus',
    returnAmount: 'Return Amount',
    totalActiveMember: 'Total Active Member',
    newRegisterMember: 'New Register Member',
    totalVisit: 'Total Visitor',
    depositChartName: 'Total Deposit Amount',
    depositAmount: 'Deposit Amount',
    depositMemberCount: 'No. of Member Deposit',
    depositTransactionCount: 'No. of Deposit Transaction',
    depositTransactionNumChartName: 'Number of Member Deposit /Number of Deposit Transaction',
    financialLevelRatio: 'Financial Level Ratio',
    vipLevelRatio: 'VIP Level Ratio',
    paymentMethod: 'Payment Method',
    ratio: 'Ratio',
    fundAmount: 'Funds',
    paymentMethodSummary: 'Payment Methods Deposit Summary',
    total: 'Total',
    bet: 'Bet',
    payout: 'Payout',
    merchantDepositSummary: 'Merchant Deposit Summary',
  },
  reportGame: {
    gamePlatform: 'Game Platform',
    gameMemberCount: 'Daily Bet Member Count',
    gameBetCount: 'Daily Bet Transaction Count',
    gameBetAmount: 'Daily Bet Amount',
    gameWinLoss: 'Daily Win/Loss Amount',
    gameMemberCountTotal: 'Total Bet Member Count',
    gameBetCountTotal: 'Total Bet Transaction Count',
    gameBetAmountTotal: 'Total Bet Amount',
    gameWinLossTotal: 'Total Win/Loss Amount',
  },
  fields: {
    account: 'Account',
    accountHolder: 'Account Holder',
    accountInfo: 'Account Information',
    accountNumber: 'Account Number',
    accountStatus: 'Account Status',
    action: 'Action',
    activeMember: 'Active Member',
    activeMemberCount: 'Active Member Count',
    activePlayer: 'Active Player',
    activeUser: 'Active User',
    add: 'Add',
    addAds: 'Add Ads Popout',
    addAffiliate: 'Add Affiliate',
    addAffiliateCommission: 'Add Affiliate Commission',
    addAffiliateDomain: 'Add Affiliate Domain',
    addAnnouncement: 'Add Announcement',
    addAnnouncementType: 'Add Announcement Type',
    addBank: 'Add Bank',
    addBankCard: 'Add Bank Card',
    addBankToPayment: 'Add Bank To Payment',
    addBankToWithdrawPlatform: 'Add Bank To Withdraw Platform',
    addBanner: 'Add Banner',
    addRewardGroup: 'Add Reward Group',
    addCurrency: 'Add Currency',
    addCurrencyRate: 'Add Currency Rate',
    addDepositSetting: 'Add Deposit Setting',
    addDomain: 'Add Domain',
    addEmail: 'Add Email',
    addFinancialLevel: 'Add Financial Level',
    addGame: 'Add Game',
    addIpLabel: 'Add IP Label',
    addJob: 'Add Job',
    addLotteryResult: 'Add Lottery Result',
    addMember: 'Add Member',
    addMemberAmountAdjust: 'Add Member Amount Adjust',
    addMemberPrivilegeBlacklist: 'Add Member Privilege Blacklist',
    addMenu: 'Add Menu',
    addNodes: 'Add Nodes',
    addPayType: 'Add Pay Type',
    addPlatform: 'Add Platform',
    addPlatformAccount: 'Add Platform Account',
    addPrivilegeInfo: 'Add Privilege Info',
    addRecipient: 'Add Recipient',
    addRemark: 'Add Remark',
    addRiskLevel: 'Add Risk Level',
    addRole: 'Add Role',
    addSite: 'Add Site',
    addSitePlatform: 'Add Site Platform',
    addSystemMessage: 'Add System Message',
    addTelephone: 'Add Telephone Number',
    addUser: 'Add User',
    addVip: 'Add VIP',
    addVipRebateRule: 'Add VIP Rebate Rule',
    addWithdrawPlatform: 'Add Withdraw Platform',
    addWithdrawSetting: 'Add Withdraw Setting',
    addWithdrawalBankBlacklist: 'Add Withdrawal Bank Blacklist',
    adjustAmount: 'Adjust Amount',
    adjustBy: 'Adjust By',
    adjustReason: 'Adjust Reason',
    adjustTime: 'Adjust Time',
    adjustType: 'Adjust Type',
    adjustment: 'Adjustment',
    adjustmentType: 'Adjustment Type',
    affiliate: 'Affiliate',
    affiliateBankCard: 'Affiliate Bank Card',
    affiliateBankCardRecord: 'Affiliate Bank Card Record',
    affiliateCode: 'Affiliate Code',
    affiliateDomainList: 'Affiliate Domain List',
    affiliateInfo: 'Affiliate Information',
    affiliateLevel: 'Affiliate Level',
    affiliateLink: 'Affiliate Link',
    affiliateList: 'Affiliate List',
    affiliateName: 'Affiliate Name',
    affiliateStatus: 'AffiliateStatus',
    affiliateTransferAmount: 'Transfer Amount',
    affiliateTransferRecord: 'Transfer Record',
    advancedSearch: 'Advanced Search',
    afterAmount: 'After Amount',
    afterBalance: 'After Balance',
    afterEdit: 'After Edit',
    amount: 'Amount',
    adjust: 'Amount Adjust',
    allvip: 'All VIP',
    allfinanciallevel: 'All Financial Status',
    allprofit: 'All Profit',
    allreviewstatus: 'All Review Status',
    announcementType: 'Announcement Type',
    applying: 'Applying',
    approve: 'Approve',
    assigned: 'Assigned',
    assignment: 'Assignment',
    auditTime: 'Audit Time',
    automaticPayment: 'Automatic Payment',
    autopay: 'Auto Pay',
    automaticPay: 'AUTOMATIC_PAYMENT',
    back: 'Back',
    balance: 'Balance',
    balanceUrl: 'Balance URL',
    bank: 'Bank',
    bankAccount: 'Bank Account',
    bankCard: 'Bank Card',
    bankCardNumber: 'Bank Card Number',
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    bankNumber: 'Bank Number',
    bankType: 'Bank Type',
    beanName: 'Bean Name',
    beforeAmount: 'Before Amount',
    beforeBalance: 'Before Balance',
    beforeEdit: 'Before Edit',
    bet: 'Bet',
    betId: 'Bet ID',
    betMoreThan: 'Bet More Than',
    betRecords: 'Bet Records',
    betStatus: 'Bet Status',
    betTime: 'Bet Time',
    birthday: 'Birthday',
    bindTypeAll: 'All',
    bindTypeNew: 'New',
    bindTypeRemove: 'Remove',
    bonus: 'Bonus',
    bonusAmount: 'Bonus Amount',
    bonusReceived: 'Bonus Received',
    bonusType: 'Bonus Type',
    byprofitasc: 'Sort by profit ASC',
    byprofitdesc: 'Sort by profit DESC',
    bydateasc: 'Sort by date ASC',
    bydatedesc: 'Sort by date DESC',
    callbackUrl: 'Callback URL',
    cancel: 'Cancel',
    cancelDeposit: 'Cancel Deposit',
    cancelRemark: 'Cancel Remark',
    cancelUpdate: 'Cancel Update',
    cancelType: 'Cancel Type',
    cardAccount: 'Card Account',
    cardAddress: 'Card Address',
    cardNumber: 'Card Number',
    cardTime: 'Action Time',
    cardTypeAll: 'All',
    cardTypeBank: 'Bank',
    cardTypeWallet: 'E-Wallet',
    cardTypeCrypto: 'Crypto',
    category: 'Category',
    cause: 'Cause',
    changeAffiliate: 'Change Affiliate',
    check: 'REVIEW',
    checkBy: 'Check By',
    checkDate: 'Check Date',
    checkExclusiveUrl: 'Check Exclusive Url',
    checkFail: 'REVIEW_FAIL',
    checkTime: 'Check Time',
    className: 'Class Name',
    clearingSum: 'Clearing Sum',
    clientType: 'Client Type',
    close: 'Close',
    code: 'Code',
    color: 'Color',
    commission: 'Commission',
    commissionModel: 'Commission Model',
    commissionPercent: 'Commission %',
    commissionRate: 'Commission Rate',
    commitTime: 'Commit Time',
    companyProfit: 'Company Profit',
    componentName: 'Component Name',
    componentPath: 'Component Path',
    composeMessage: 'Compose Message',
    consumingTime: 'Consuming Time',
    confirm: 'Confirm',
    confirmAndImport: 'Confirm and Import',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    confirmPay: 'Confirm Pay',
    confirmStatus: 'Confirm Status',
    confirmBy: 'Confirm By',
    content: 'Content',
    convertRate: 'Convert Rate',
    copy: 'Copy',
    copyPayment: 'Copying payment',
    copyTo: 'Copy to',
    copyToOtherLevel: 'Copy to other level',
    country: 'Country',
    countryImage: 'Country Image',
    createBy: 'Create By',
    createTime: 'Create Time',
    createVote: 'Create Vote',
    cronExpression: 'Cron Expression',
    currency: 'Currency',
    currencyCode: 'Currency Code',
    currencyName: 'Currency Name',
    currencyRate: 'Currency Rate',
    currentPassword: 'Current Password',
    customerSupportAddress: 'Customer Support Address',
    dailyDeposit: 'Daily Deposit',
    dailyDepositAmount: 'Daily Deposit Amount',
    dailyWithdraw: 'Daily Withdraw',
    dailyWithdrawAmount: 'Daily Withdraw Amount',
    dailyFailAmount: 'Daily Fail Amount',
    dailyFailDeposit: 'Daily Fail Deposit',
    dailyMemberFailDeposit: 'Daily Member Fail Deposit',
    dailyFailWithdraw: 'Daily Fail Withdraw',
    dailyMemberFailWithdraw: 'Daily Member Fail Withdraw',
    dailySuccessAmount: 'Daily Success Amount',
    dailySuccessDeposit: 'Daily Success Deposit',
    dailyMemberSuccessDeposit: 'Daily Member Success Deposit',
    dailySuccessWithdraw: 'Daily Success Withdraw',
    dailyMemberSuccessWithdraw: 'Daily Member Success Withdraw',
    dailySuccessPercentage: 'Daily Success Percentage',
    dailyTotalAmount: 'Daily Total Amount',
    dailyTotalRedeem: 'Daily Total Redeem',
    dayWithdrawCount: 'Daily Withdraw Count',
    date: 'Date',
    dateReceived: 'Date Received',
    dateType: 'Date Type',
    deduct: 'Deduct',
    deductMemberAmountAdjust: 'Deduct Member Amount Adjust',
    defaultFinancialLevel: 'Default Financial Level',
    defaultRiskLevel: 'Default Risk Level',
    defaultVip: 'Default VIP',
    delete: 'Delete',
    deleteRole: 'Delete Role',
    deposit: 'Deposit',
    depositAmount: 'Deposit Amount',
    depositBettingAmount: 'Deposit/Betting Amount',
    depositCount: 'Deposit Count',
    depositDate: 'Deposit Date',
    depositExchangeRate: 'Deposit Exchange Rate',
    depositMemberCount: 'Deposit Member Count',
    depositSerialNo: 'Deposit Serial Number',
    depositStatus: 'Deposit Status',
    describe: 'Describe',
    description: 'Description',
    desktopImage: 'Desktop Image',
    desktopBanner: 'Desktop Banner',
    details: 'Details',
    device: 'Device',
    dif: 'Deposit Difference',
    disable: 'Disable',
    disableAffiliate: 'Disable Affiliate',
    disableType: 'Disable Type',
    distributePrivilege: 'Distribute Privilege',
    domain: 'Domain',
    domainCount: 'Domain Count',
    domainList: 'Domain List',
    done: 'Done',
    downgradeToApplying: 'DOWNGRADE_TO_APPLYING',
    downgradeToWaitPay: 'WAIT_FOR_PAYMENT',
    downlineAffiliate: 'Downline Affiliate',
    downlineCommission: 'Downline Commission',
    downlineCommissionRate: 'Downline Commission Rate',
    downlineMember: 'Downline Member',
    downlineProfit: 'Downline Profit',
    downloadTemplate: 'Download Template',
    edit: 'Edit',
    editAds: 'Edit Ads Popout',
    editAffiliateCommission: 'Edit Affiliate Commission',
    editAffiliateDomain: 'Edit Affiliate Domain',
    editAmount: 'Edit Amount',
    editAnnouncement: 'Edit Announcement',
    editAnnouncementType: 'Edit Announcement Type',
    editBank: 'Edit Bank',
    editBankCard: 'Edit Bank Card',
    editBanner: 'Edit Banner',
    editCurrency: 'Edit Currency',
    editCurrencyRate: 'Edit Currency Rate',
    editDepositSetting: 'Edit Deposit Setting',
    editDetails: 'Edit Details',
    editEmail: 'Edit Email',
    editFinancialLevel: 'Edit Financial Level',
    editGame: 'Edit Game',
    editIpLabel: 'Edit IP Label',
    editJob: 'Edit Job',
    editMenu: 'Edit Menu',
    editPayType: 'Edit Pay Type',
    editPlatform: 'Edit Platform',
    editPlatformAccount: 'Edit Platform Account',
    editPrivilegeInfo: 'Edit Privilege Info',
    editRemark: 'Edit Remark',
    editRewardGroup: 'Edit Reward Group',
    editRiskLevel: 'Edit Risk Level',
    editRole: 'Edit Role',
    editSite: 'Edit Site',
    editSitePlatform: 'Edit Site Platform',
    editTelephone: 'Edit Telephone Number',
    editTimeType: 'Edit Time Type',
    editType: 'Edit Type',
    editUser: 'Edit User',
    editVip: 'Edit VIP',
    editVipRebateRule: 'Edit VIP Rebate Rule',
    editWithdrawPlatform: 'Edit Withdraw Platform',
    editWithdrawSetting: 'Edit Withdraw Setting',
    email: 'Email',
    endDate: 'End Date',
    endTime: 'End Time',
    exchangeRate: 'Exchange Rate',
    exclusiveUrlWeb: 'Exclusive Url（Web）',
    exclusiveUrlWap: 'Exclusive Url（App）',
    exclusiveUrlApp: 'Exclusive Url',
    exclusiveUrlCashier: 'Exclusive Url',
    exclusiveUrl: 'Exclusive Url',
    exportToExcel: 'Export to Excel',
    extraParam: 'Extra Param',
    fail: 'Fail',
    failReason: 'Fail Reason',
    features: 'Features',
    feedbackType: 'Feedback Type',
    feedbackTypeAll: 'All',
    feedbackTypeDeposit: 'Deposit',
    feedbackTypeWithdraw: 'Withdraw',
    feedbackStatus: 'Feedback Status',
    feedbackStatusAll: 'All',
    feedbackStatusPending: 'Pending',
    feedbackStatusSuccess: 'Success',
    feedbackTime: 'Feedback Time',
    feeRate: 'Fee Rate',
    finalSum: 'Final Sum',
    financialLevel: 'Financial Level',
    financeType: 'Type',
    financeRemark: 'Finance Remark',
    financeStatus: 'Status',
    financePhotos: 'Photos',
    finishDate: 'Finish Date',
    firstDeposit: 'First Deposit',
    firstDepositMemberCount: 'First Deposit Count',
    firstDepositMember: 'First Deposit Member',
    freeze: 'Freeze',
    freezeMember: 'Freeze Member',
    freezeType: 'Freeze Type',
    frequency: 'Frequency',
    ftd: 'FTD',
    ftdAverage: 'FTD Average',
    ftdAmount: 'FTD Amount',
    ftdCount: 'FTD Count',
    ftdTime: 'FTD Time',
    ftdTxn: 'FTD Txn',
    fundingInfo: 'Funding Information',
    gameCode: 'Game Code',
    gameName: 'Game Name',
    gameType: 'Game Type',
    generateSummary: 'Generate Summary',
    hasPromo: 'Has Promotion',
    hidden: 'Hidden',
    icon: 'Icon',
    id: 'ID',
    identifyCode: 'Identify Code',
    import: 'Import',
    inbox: 'Inbox',
    ip: 'Access IP',
    itsYou: "it's you",
    jobName: 'Job Name',
    label: 'Label',
    lastLogin: 'Last Login',
    lastLoginAddress: 'Last Login Address',
    lastLoginIp: 'Last Login IP',
    lastLoginTime: 'Last Login Time',
    lastMonth: 'Last Month',
    last3Months: 'Last 3 Months',
    last6Months: 'Last 6 Months',
    lastWeek: 'Last Week',
    level: 'Level',
    levelUpCredit: 'Level Up Credit',
    localCurrencyAmount: 'Local Currency Amount',
    lockStatus: 'Lock Status',
    login: 'Login',
    loginAddress: 'Login Address',
    loginFailedReason: 'Login Failed Reason',
    loginInfo: 'Login Information',
    loginIp: 'Login IP',
    loginName: 'Login Name',
    loginNameSeparateComma: 'Login Name (Separate by comma)',
    loginStatus: 'Login Status',
    loginTime: 'Login Time',
    loginUrl: 'Login URL',
    loginWay: 'Login Way',
    logoutPlayer: 'Logout Player',
    lotteryRecordList: 'Lottery Record List',
    lotteryResultList: 'Lottery Result List',
    mallCode: 'Mall Code',
    mallExtraKey: 'Mall Extra Key',
    mallKey: 'Mall Key',
    mallName: 'Mall Name',
    manualPay: 'Manual Pay',
    massImport: 'Mass Import',
    maxBonus: 'Maximum Bonus',
    maxDailyWithdraw: 'Maximum Daily Withdraw Amount',
    maxDailyWithdrawTimes: 'Maximum Times of Daily Withdrawal',
    maxDeposit: 'Maximum Deposit',
    maxWithdrawAmount: 'Maximum Withdraw Amount',
    member: 'Member',
    memberBankCard: 'Member Bank Card',
    memberBankCardRecord: 'Member Bank Card Record',
    memberBetRecord: 'Member Bet Record',
    memberCommission: 'Member Commission',
    memberId: 'Member ID',
    memberInfo: 'Member Info',
    memberList: 'Member List',
    memberName: 'Member Name',
    memberPlatformAction: 'Action（Manual Register/Delete）',
    memberPlatformUpdate: 'Sync Password',
    memberPrivilegeBlacklist: 'Member Privilege Blacklist',
    memberReport: 'Member Report',
    memberType: 'Member Type',
    memberWithdrawLog: 'Member Withdraw Log',
    menuGame: 'Menu Game Sequence',
    menuIcon: 'Menu Icon',
    menuTitle: 'Menu Title',
    menuType: 'Menu Type',
    message: 'Message',
    min: 'Min Profit',
    max: 'Max Profit',
    minno: 'Please insert min',
    maxno: 'Please insert max',
    memberRemark: 'Member Remark',
    minActiveMemberCount: 'Minimum Active Member Count',
    minDeposit: 'Minimum Deposit',
    minTotalCommission: 'Minimum Total Commission',
    minWithdrawAmount: 'Minimum Withdraw Amount',
    mobile: 'Mobile',
    mobileImage: 'Mobile Image',
    mobileBanner: 'Mobile Banner',
    mobileMessage: 'Mobile Message',
    mobileWay: 'Mobile',
    moneyChange: 'Money Change',
    month: 'Month',
    name: 'Name',
    needRegister: 'Need Register',
    netProfit: 'Net Profit',
    newMember: 'New Member',
    newMemberCount: 'New Member Count',
    newPassword: 'New Password',
    nextActivationTime: 'Next Activation Time',
    nextGetBetEndTime: 'Next Get Bet End Time',
    nextGetBetIndex: 'Next Get Bet Index',
    nextGetBetPage: 'Next Get Bet Page',
    nextGetBetStartTime: 'Next Get Bet Start Time',
    nextLevel: 'Next Level',
    nextLevelPoint: 'Next Level Point',
    noData: 'No Data',
    noOfDeduction: 'Number of Deduction',
    noOfDepositTimes: 'Number of Deposit Times',
    noOfPrivilegeClaimed: 'Number of Privilege Claimed',
    noOfReimbursement: 'Number of Reimbursement',
    noOfWinners: 'No of Winners',
    noOfWithdrawTimes: 'Number of Withdraw Times',
    notRegister: 'Not Register',
    notice: 'Notice',
    open: 'Open',
    operate: 'Operate',
    operator: 'Operator',
    operateTime: 'Operate Time',
    operationTime: 'Operation Time',
    operationType: 'Operation Type',
    orderNo: 'Order No',
    param: 'Parameter',
    parentSite: 'Parent Site',
    password: 'Password',
    pause: 'Pause',
    pay: 'Pay',
    payBy: 'Pay By',
    payMessage: 'Pay Message',
    payMessageMobile: 'Pay Message Mobile',
    payResultType: 'Pay Result Type',
    payTime: 'Pay Time',
    payType: 'Pay Type',
    payTypeCode: 'Pay Type Code',
    payTypeName: 'Pay Type Name',
    payment: 'Payment',
    paymentArea: 'Payment Area',
    paymentBy: 'Payment By',
    paymentCard: 'Payment Card',
    paymentDate: 'Payment Date',
    paymentMethod: 'Payment Method',
    paymentName: 'Payment Name',
    paymentOnGoing: 'Payment on going',
    paymentType: 'Payment Type',
    payout: 'Payout',
    pcWay: 'PC',
    pending: 'SUSPEND',
    permission: 'Permission',
    permissionAssignment: 'Permission Assignment',
    platform: 'Platform',
    platformAccount: 'Platform Account',
    platformId: 'Platform ID',
    platformBalance: 'Platform Balance',
    platformCode: 'Platform Code',
    platformFundTransfer: 'Platform Fund Transfer',
    platformName: 'Platform Name',
    pleaseChoose: 'Please choose',
    pleaseInput: 'Please input',
    previousLevel: 'Previous Level',
    previousLevelName: 'Previous Level Name',
    privilege: 'Privilege',
    privilegeCode: 'Privilege Code',
    privilegeId: 'Privilege ID',
    privilegeName: 'Privilege Name',
    privilegeRedeemed: 'Privilege Redeemed',
    privilegeType: 'Privilege Type',
    profit: 'Profit',
    profitpositive: 'Positive Profit',
    profitnegative: 'Negative Profit',
    promo: 'Promo',
    promoCode: 'Promo Code',
    promoPages: 'Promotion Pages',
    promoStyle: 'Promo Style',
    promoType: 'Promo Type',
    queryNumber: 'Query Number',
    queryOption: 'Query Option',
    queryRestriction: 'Query Restriction',
    rate: 'Rate',
    realName: 'Real Name',
    englishName: 'English Name',
    realVirtualVotes: 'Real Votes + Virtual Votes',
    reason: 'Reason',
    reasonType: 'Reason Type',
    reasonTemplate: 'Reason Template',
    rebate: 'Rebate',
    rebatePercentage: 'Rebate %',
    maxRebate: 'Max Rebate',
    receiveTime: 'Receive Time',
    receiveType: 'Receive Type',
    recipient: 'Recipient',
    recordDate: 'Record Date',
    recordTime: 'Record Time',
    redeemedBy: 'Redeemed By',
    redirect: 'Redirect',
    reenterPassword: 'Re-Enter Password',
    referrer: 'Referrer',
    register: 'Register',
    registered: 'Registered',
    registerAddress: 'Register Address',
    registerCount: 'Register Count',
    registerDevice: 'Register Device',
    registerHost: 'Register Host',
    registerIp: 'Register IP',
    registerMember: 'Register Member',
    registerTime: 'Register Time',
    registrationDate: 'Registration Date',
    referredFriends: 'Referred Friends',
    remark: 'Remark',
    remove: 'Remove',
    reply: 'Reply',
    requestUrl: 'Request URL',
    requestParam: 'Request Parameter',
    responseBody: 'Response Body',
    reset: 'Reset',
    result: 'Result',
    resultDate: 'Result Date',
    resultNumber: 'Result Number',
    resultTime: 'Result Time',
    resume: 'RESUME',
    revenueShare: 'Revenue Share',
    review: 'Review',
    reviewby: 'Review By',
    reviewno: 'Not Review',
    reviewsuccess: 'Correct',
    reviewstatus: 'Review Status',
    reviewfail: 'Wrong',
    rewardGroup: 'Reward Group',
    rewardGroupName: 'Group Name',
    rewardGroupDownlineQuota: 'Downline Quota',
    riskControl: 'Risk Control',
    riskLevel: 'Risk Level',
    role: 'Role',
    roleList: 'Role List',
    roleName: 'Role Name',
    rollover: 'Rollover',
    router: 'Router',
    ruleParam: 'Rule Param',
    ruleType: 'Rule Type',
    run: 'Run',
    save: 'Save',
    saveAsNewPayment: 'Save as new payment',
    schemaName: 'Schema Name',
    search: 'Search',
    searchCondition: 'Search Condition',
    sendTime: 'Send Time',
    select: 'Select',
    selectNodeAddTo: 'Select nodes to add to ',
    selected: 'Selected',
    selectedNumber: 'Selected Number',
    send: 'Send',
    sequence: 'Sequence',
    serialNo: 'Serial Number',
    settlement: 'Settlement',
    settleTime: 'Settle Time',
    show: 'Show',
    sid: 'SID',
    site: 'Site',
    siteCode: 'Site Code',
    siteList: 'Site List',
    siteName: 'Site Name',
    sn: 'S/N',
    smsPhone: 'Phone',
    smsContent: 'Content',
    smsStatus: 'Status',
    smsSendTime: 'Send Time',
    smsType: 'Type',
    sorting: 'Sorting',
    sourceType: 'Source Type',
    sportBetResult: 'Sport Bet Result',
    state: 'State',
    startCopy: 'Start Copy',
    startUpdate: 'Start Update',
    startDate: 'Start Date',
    startTime: 'Start Time',
    status: 'Status',
    stopAfterFailure: 'Stop After Failure',
    subject: 'Subject',
    subSiteCount: 'Sub Site Count',
    subtotal: 'Subtotal',
    success: 'Success',
    successfullyExport: 'Successfully Export',
    summaryAdjustment: 'Adjustment',
    summaryBonus: 'Bonus',
    summaryProfit: 'Profit',
    summaryReport: 'Summary Report',
    summaryTotalBet: 'Total Bet Count',
    superiorAffiliateInfo: 'Superior Affiliate Info',
    superiorAffiliateName: 'Superior Affiliate Name',
    superiorAffiliateCode: 'Superior Affiliate Code',
    superiorAffiliateLevel: 'Superior Affiliate Level',
    superiorCategory: 'Superior Category',
    supplement: 'Supplement',
    supplementDeposit: 'Supplement Deposit',
    supportType: 'Support Type',
    suspend: 'Suspend',
    switch: 'Switch',
    switchPayment: 'Switch Payment',
    systemPayment: 'System Payment',
    teamName: 'Team Name',
    teamNameEn: 'Team Name English',
    teamNameLocal: 'Team Name Local',
    telephone: 'Telephone',
    thirdParty: 'Third Party',
    thirdPartyName: 'Third Party Name',
    thirdSerialNo: 'Third Serial Number',
    thisMonth: 'This Month',
    thisWeek: 'This Week',
    tips: 'Tips',
    title: 'Title',
    toApplying: 'To Applying',
    toBeforePaid: 'To Be Paid',
    toBePaid: 'To Be Paid',
    toChecking: 'To Checking',
    today: 'Today',
    toPay: 'To Pay',
    toPaymentOnGoing: 'To Payment On Going',
    toreview: 'To Review',
    toSuspend: 'To Suspend',
    total: 'Total',
    totalAdjustment: 'Total Adjustment',
    totalBalance: 'Total Balance',
    totalBet: 'Total Bet',
    totalBetRecords: 'Total Bet Records',
    totalBonus: 'Total Bonus',
    totalCommission: 'Total Commission',
    totalCompanyProfit: 'Total Company Profit',
    totalDeductAmount: 'Total Deduct Amount',
    totalDeposit: 'Total Deposit',
    totalDepositAmount: 'Total Deposit Amount',
    totalDepositCount: 'Total Deposit Count',
    totalDepositedAmount: 'Total deposited amount',
    totalDownlineAffiliates: 'Total Downline Affiliates',
    totalDownlineMembers: 'Total Downline Members',
    totalFailDeposit: 'Total Fail Deposit',
    totalFailDepositAmount: 'Total Fail Deposit Amount',
    totalFailWithdraw: 'Total Fail Withdraw',
    totalFailWithdrawAmount: 'Total Fail Withdraw Amount',
    totalFirstDeposit: 'Total First Deposit',
    totalNoOfDeposits: 'Total number of deposits',
    totalNoOfWithdrawals: 'Total number of withdrawals',
    totalPayout: 'Total Payout',
    totalPrivilegeAmount: 'Total Privilege Amount',
    totalPrivilegeClaimAmount: 'Total Privilege Claim Amount',
    totalPromo: 'Total Promotion',
    totalProfit: 'Total Profit',
    totalRedeem: 'Total Redeem',
    totalRegister: 'Total Register',
    totalReimburseAmount: 'Total Reimburse Amount',
    totalSuccessDeposit: 'Total Success Deposit',
    totalSuccessDepositAmount: 'Total Success Deposit Amount',
    totalSuccessWithdraw: 'Total Success Withdraw',
    totalSuccessWithdrawAmount: 'Total Success Withdraw Amount',
    totalTime: 'Total Time',
    totalTransferIn: 'Total Transfer In',
    totalTransferOut: 'Total Transfer Out',
    toUnderReview: 'To Under Review',
    totalWithdraw: 'Total Withdraw',
    totalWithdrawAmount: 'Total Withdraw Amount',
    totalWithdrawCount: 'Total Withdraw Count',
    totalWithdrawnAmount: 'Total withdrawn amount',
    transaction: 'Transaction',
    transactionTime: 'Transaction Time',
    transactionId: 'Transaction ID',
    transferDate: 'Transfer Date',
    transferId: 'Transfer ID',
    transferIn: 'Transfer In',
    transferOut: 'Transfer Out',
    transferTime: 'Transfer Time',
    transferType: 'Transfer Type',
    triggerType: 'Trigger Type',
    turnoverMultiple: 'Turnover Multiple',
    txnId: 'Txn ID',
    txnTime: 'Txn Time',
    type: 'Type',
    underReview: 'Under review',
    unlockMember: 'Unlock Member',
    unlockUser: 'Unlock User',
    url: 'Access URL',
    update: 'Update',
    updateBy: 'Updated By',
    updateCommissionModel: 'Update Commission Model',
    updateCommissionRate: 'Update Commission Rate',
    updateFinancialLevel: 'Update Financial Level',
    updatePassword: 'Update Password',
    updateRiskLevel: 'Update Risk Level',
    updateTime: 'Updated Time',
    updateVIPLevel: 'Update VIP Level',
    upgradeToCheck: 'UPGRADE_TO_UNDER_REVIEW',
    upgradeToPaymentOnGoing: 'UPGRADE_TO_PAYMENT_ON_GOING',
    upload: 'Upload',
    username: 'Username',
    userType: 'User Type',
    validate: 'Validate',
    validBet: 'Valid Bet',
    value: 'Value',
    view: 'View',
    viewLog: 'View Log',
    vipLevel: 'VIP Level',
    virtualVotes: 'Virtual Votes',
    visitNumber: 'Visit Number',
    voteTime: 'Vote Time',
    walletType: 'Wallet Type',
    way: 'Way',
    web: 'Web',
    webMessage: 'Web Message',
    withdraw: 'Withdraw',
    withdrawArea: 'Withdraw Area',
    withdrawExchangeRate: 'Withdraw Exchange Rate',
    withdrawalFailureCause: 'Withdrawal Failure Cause',
    withdrawalFailureType: 'Withdrawal Failure Type',
    withdrawAmount: 'Withdraw Amount',
    withdrawCard: 'Withdraw Card',
    withdrawCode: 'Withdraw Code',
    withdrawCount: 'Withdraw Count',
    withdrawDate: 'Withdraw Date',
    withdrawMemberCount: 'Withdraw Member Count',
    withdrawName: 'Withdraw Name',
    withdrawPayoutBonus: 'Withdrawal/Payout/Bonus',
    withdrawPlatform: 'Withdraw Platform',
    withdrawPlatformList: 'Withdraw Platform List',
    withdrawPlatformName: 'Withdraw Platform Name',
    withdrawStatus: 'Withdraw Status',
    yesterday: 'Yesterday',
    tigerCardType: 'Card Type',
    tigerCardOpeType: 'Operate Type',
    tigerCardSource: 'Source/Target',
    tigerCardBeforeCount: 'Before Count',
    tigerCardRealCount: 'Gold Tiger Card Count',
    tigerCardVirtualCount: 'Virtual Card Count',
    tigerCardPeriod: 'Period',
    tigerCardTime: 'Lottery Time',
    tigerCardBonusTime: 'Receive Time',
    timeType: 'Time Type',
    count: 'Count',
    host: 'host',
    port: 'port',
    user: 'user',
    from: 'from',
    addEmailSetting: 'Add Email Setting',
    editEmailSetting: 'Edit Email Setting',
    signName: 'Sign Name',
    secretId: 'Secret ID',
    secretKey: 'Secret Key',
    appId: 'Application ID',
    templateId: 'Template ID',
    addSmsSetting: 'Add SMS Setting',
    editSmsSetting: 'Edit SMS Setting',
  },
  message: {
    addRemarkSuccess: 'Add Remark Success',
    addSuccess: 'Add Success',
    adjustSuccess: 'Adjust Amount Success',
    affiliateApproved: 'Affiliate Approved',
    affiliateDisabled: 'Affiliate Disabled',
    autopaySuccess: 'Autopay Success',
    cancelDepositSuccess: 'Cancel Deposit Success',
    changeAffiliateSuccess: 'Change Affiliate Success',
    changeSuccess: 'Change Success',
    confirmCopy: 'Do you want to copy from ',
    confirmCopyTo: ' to ',
    confirmDelete:
      'Confirm that you want to delete these data, the operation cannot be undone',
    confirmSettlement: 'Confirm that you want to proceed with the settlement?',
    confirmToChecking:
      'Confirm that you want revert the settlement to checking?',
    confirmToPay: 'Confirm that you want proceed to payment?',
    confirmToCheck: 'Do you want to update？',
    confirmUpdate: 'Do you want to update?',
    confirmUpdatePayment: 'Do you want to update payment?',
    copyToClipboard: ' has been copied to clipboard.',
    deleteSuccess: 'Delete Success',
    depositSuccess: 'Deposit Success',
    editAmountSuccess: 'Edit Amount Success',
    editRemarkSuccess: 'Edit Remark Success',
    editSuccess: 'Edit Success',
    editMemberDetail: 'Edit Member Detail',
    enterMemberDetails: 'Enter Member Details',
    failedToUploadImage: 'Failed to upload image',
    freezeMemberSuccess: 'Freeze Member Success',
    found: 'Found ',
    importSuccess: 'Import Success',
    inputDigits: 'Please input digits',
    inputPassword: 'Please input the password',
    inputPasswordAgain: 'Please input the password again',
    invalidFileType: 'Invalid file type ',
    length6To12: 'Length should be 6 to 12',
    levelUpCreditMoreThan: 'Level up credit must be more than ',
    logoutPlayerSuccess: 'Logout Player Success',
    maxGreaterThanMin: 'Maximum must be greater than minimum',
    memberNotInSite: 'Member could not be found in this site',
    multipleQuerySeparatedBySpace:
      'Multiple query conditions are separated by spaces',
    noAvailablePrivilege: 'Currently there are no available privilege',
    passwordLength:
      'The password cannot be less than 6 digits or more than 12 digits',
    paymentUpdate: 'Copy to other way or level? if not just press update',
    paySuccess: 'Pay Success',
    promoDistributionSuccess: 'Promo Distribution Success',
    reenterPassword: 'Please re-enter the password',
    registerSuccess: 'Register Success',
    replySuccess: 'Reply Success',
    removePreviousLevel: 'Please remove previous level',
    removeRemarkSuccess: 'Remove Remark Success',
    selectPreviousLevel: 'Please select previous level',
    selectSiteFirst: 'Please select a site first',
    selectSiteAndGameTypeFirst: 'Please select a site and a game type first',
    selectPaymentType: 'Please input payment type',
    selectMobilePayment: 'Please select mobile payment',
    selectWebPayment: 'Please select web payment',
    settlementToChecking: 'Updated to checking',
    settlementToPay: 'Updated to payment',
    settled: 'Successfully settled',
    summarySuccess: 'Summary for the input period will be generated, please kindly wait',
    transferSuccess: 'Transfer Success',
    twoPasswordNotMatch: 'Two password does not match',
    unlockMemberSuccess: 'Unlock Member Success',
    unlockUserSuccess: 'Unlock User Success',
    updateCommissionModelSuccess: 'Update Commission Model Success',
    updateCommissionRateSuccess: 'Update Commission Rate Success',
    updateFinancialLevelSuccess: 'Update Financial Level Success',
    updatePasswordSuccess: 'Update Password Success',
    updatePayment: 'Update Payment',
    updateProceed: 'Update nodes to proceed to payment edit',
    updateRiskLevelSuccess: 'Update Risk Level Success',
    updateSuccess: 'Update Success',
    updateTimeTypeSuccess: 'Update Time Type Success',
    updateToApplySuccess: 'Update to Apply Success',
    updateToBeforePaidSuccess: 'Update to Before Paid Success',
    updateToFailSuccess: 'Update to Fail Success',
    updateToPaySuccess: 'Update to Payment On Going Success',
    updateToSuspendSuccess: 'Update to Suspend Success',
    updateToUnderReviewSuccess: 'Update to Under Review Success',
    updateUserTypeSuccess: 'Update UserType Success',
    updateVIPLevelSuccess: 'Update VIP Level Success',
    useCommaToSeparateDomain:
      'Please use "," to separate domain name if insert multiple domain',
    validateAdsTypeRequired: 'Ads Type is required',
    validateActionRequired: 'Action is required',
    validateActivePlayerRequired: 'Active Player is required',
    validateAdjustAmountRequired: 'Adjust Amount is required',
    validateAdjustReasonRequired: 'Adjust Reason is required',
    validateAdjustTypeRequired: 'Adjust Type is required',
    validateAffiliateCodeRequired: 'Affiliate Code is required',
    validateAffiliateLevelRequired: 'Affiliate Level is required',
    validateAffiliateNameRequired: 'Affiliate Name is required',
    validateAlphaNumericOnly: 'Please enter string and number only',
    validateAmountRequired: 'Amount is required',
    validateAnnouncementTypeRequired: 'Announcement Type is required',
    validateAtLeastSixChar: 'Please enter at least 6 characters',
    validateBankRequired: 'Bank is required',
    validateBankCardNumberRequired: "Bank Card Number is required",
    validateBankCodeRequired: 'Bank Code is required',
    validateBankNameRequired: 'Bank Name is required',
    validateBankTypeRequired: 'Bank Type is required',
    validateBeanNameRequired: 'Bean Name is required',
    validateBonusAmountRequired: 'Bonus Amount is required',
    validateBonusAmountRatioRequired: 'Bonus Amount Ratio is required',
    validateBonusTypeRequired: 'Bonus Type is required',
    validateCardAccountRequired: 'Card Account is required',
    validateCardAddressRequired: 'Card Address is required',
    validateCardNumberRequired: 'Card Number is required',
    validateCategoryRequired: 'Category is required',
    validateCauseRequired: 'Cause is required',
    validateClassNameRequired: 'Class Name is required',
    validateCodeRequired: 'Code is required',
    validateColorRequired: 'Color is required',
    validateCommissionRequired: 'Commission is required',
    validateCommissionFormat: 'Commission must be between 0 to 1',
    validateCommissionModelRequired: 'Commission Model is required',
    validateConfirmPasswordRequired: 'Confirm Password is required',
    validateConfirmPasswordSize: 'Confirm Password has to be 6-12 characters',
    validateContentRequired: 'Content is required',
    validateCopyFinancialLevel: 'Please select at least 1 level to copy',
    validateCountryImageRequired: 'Country Image is required',
    validateCronExpressionFormat: 'Cron Expression invalid format',
    validateCronExpressionRequired: 'Cron Expression is required',
    validateCurrencyRequired: 'Currency is required',
    validateCurrencyCodeRequired: 'Currency Code is required',
    validateCurrencyNameRequired: 'Currency Name is required',
    validateCustomerSupportAddressFormat:
      'Customer Support Address JSON String is invalid',
    validateDayWithdrawCountRequired: 'Daily Withdraw Count is required',
    validateDepositExchangeRateRequired: 'Deposit Exchange Rate is required',
    validateDesktopImageRequired: 'Desktop Image is required',
    validateDesktopBannerRequired: 'Desktop Banner is required',
    validateDeviceRequired: 'Device is required',
    validateDomainRequired: 'Domain is required',
    validateEmailRequired: 'Email is required',
    validateEmailFormat: 'Please input the correct email address format',
    validateEndDateRequired: 'End Date is required',
    validateEndTimeRequired: 'End Time is required',
    validateExchangeRateRequired: 'Exchange Rate is required',
    validateFailReasonRequired: 'Fail Reason is required',
    validateFeeRateRequired: 'Fee Rate is required',
    validateFreezeTypeRequired: 'Freeze type is required',
    validateFinancialLevelRequired: 'Financial level is required',
    validateFrequencyRequired: 'Frequency is required',
    validateGameCodeRequired: 'Game Code is required',
    validateGameNameRequired: 'Game Name is required',
    validateGameTypeRequired: 'Game Type is required',
    validateIconRequired: 'Icon is required',
    validateIdentifyCodeRequired: 'Identify Code is required',
    validateIpRequired: 'IP is required',
    validateJobNameRequired: 'Job Name is required',
    validateLabelRequired: 'Label is required',
    validateLevelRequired: 'Level is required',
    validateLevelNameRequired: 'Level Name is required',
    validateLevelUpCreditRequired: 'Level Up Credit is required',
    validateLoginNameRequired: 'Login name is required',
    validateLoginNameSize: 'Login name has to be 6-12 characters',
    validateMallCodeRequired: 'Mall Code is required',
    validateMallKeyRequired: 'Mall Key is required',
    validateMallNameRequired: 'Mall Name is required',
    validateMaxDailyWithdrawRequired: 'Maximum Daily Withdraw is required',
    validateMaxDailyWithdrawNumber:
      'Maximum Daily Withdraw must not be lesser than or equal to 0',
    validateMaxDailyWithdrawGreater:
      'Max Daily Withdraw must be greater than max withdraw amount',
    validateMaxDailyWithdrawTimesRequired:
      'Maximum Daily Withdraw Time is required',
    validateMaxDailyWithdrawTimesNumber:
      'Maximum Daily Withdraw Time must not be lesser than or equal to 0',
    validateMaxDepositRequired: 'Maximum Deposit is required',
    validateMaxDepositGreater: 'Max Deposit must be greater than min deposit',
    validateMaxWithdrawAmountRequired: 'Maximum Withdraw Amount is required',
    validateMaxWithdrawAmountNumber:
      'Maximum Withdraw Amount must not be lesser than or equal to 0',
    validateMaxWithdrawAmountGreater:
      'Max Withdraw Amount must be greater than min withdraw amount',
    validateMemberTypeRequired: 'Member type is required',
    validateMenuTitleRequired: 'Menu Title is required',
    validateMinDepositRequired: 'Minimum deposit is required',
    validateMinDepositLesser:
      'Min Deposit Amount must be lesser than max deposit amount',
    validateMinWithdrawRequired: 'Minimum withdraw is required',
    validateMinWithdrawNumber:
      'Minimum withdraw must not be lesser than or equal to 0',
    validateMinWithdrawLesser:
      'Min Withdraw Amount must be lesser than max withdraw amount',
    validateMobileImageRequired: 'Mobile Image is required',
    validateMobileBannerRequired: 'Mobile Banner is required',
    validateMobileMessageRequired: 'Mobile Message is required',
    validateNameRequired: 'Name is required',
    validateNeedRegisterRequired: 'Need Register is required',
    validateNetProfitRequired: 'Net Profit is required',
    validateNextActivationTimeRequired: 'Next Activation Time is required',
    validateNextGetBetEndTime: 'Next Get Bet End Time is required',
    validateNextGetBetPage: 'Next Get Bet Page is required',
    validateNextGetBetStartTime: 'Next Get Bet Start Time is required',
    validateNoNullValue: 'No null value can exist',
    validateNumberOnly: 'Please enter number only',
    validateNumberFourDecimalOnly:
      'Please enter number greater than 0 and only 4 decimal places',
    validateOnlyThreeChar: 'Please enter 3 characters',
    validateParamRequired: 'Parameter is required',
    validatePasswordRequired: 'Password is required',
    validatePasswordSize: 'Password has to be 6-12 characters',
    validatePaymentNameRequired: 'Payment Name is required',
    validatePaymentTypeRequired: 'Payment Type is required',
    validatePayResultTypeRequired: 'Pay Result Type is required',
    validatePayTypeRequired: 'Pay Type is required',
    validatePayTypeCodeRequired: 'Pay Type Code is required',
    validatePayTypeNameRequired: 'Pay Type Name is required',
    validatePayTypeNameCodeDifferent:
      'Pay Type Name and Pay Type Code can not be same value',
    validatePlatformRequired: 'Platform is required',
    validatePlatformAccountRequired: 'Platform Account is required',
    validatePlatformCodeRequired: 'Platform Code is required',
    validatePlatformNameRequired: 'Platform Name is required',
    validatePrivilegeRequired: 'Privilege Name is required',
    validatePromoCodeRequired: 'Promo Code is required',
    validatePromoTypeRequired: 'Promo Type is required',
    validateQueryNumberRequired: 'Query Number is required',
    validateQueryRestrictionRequired: 'Query Restriction is required',
    validateRateRequired: 'Rate is required',
    validateRealNameRequired: 'Real Name is required',
    validateReasonRequired: 'Reason is required',
    validateReasonTypeRequired: 'Reason Type is required',
    validateRebatePercentageRequired: 'Rebate Percentage is required',
    validateMaxRebateRequired: 'Max rebate is required',
    validateRecordTimeRequired: 'Record Time is required',
    validateRedirectRequired: 'Redirect is required',
    validateReenterPasswordRequired: 'Please re-enter the password',
    validateRemarkRequired: 'Remark is required',
    validateRequestUrlRequired: 'Request URL is required',
    validateResultDateRequired: 'Result Date is required',
    validateResultNumber: '3 digits number is required',
    validateRebateFormat: 'Rebate must be between 0 to 1',
    validateRevenueShareFormat: 'Revenue Share must be between 0 to 1',
    validateRewardGroupRequired: 'Reward Group is required',
    validateRewardGroupNameRequired: 'Reward Group Name is required',
    validateRewardGroupDownlineQuotaRequired: 'Downline Quota is required',
    validateRewardGroupDownlineQuotaFormat:
      'Downline Quota must be greater than 0',
    validateRiskLevelRequired: 'Risk level is required',
    validateRoleRequired: 'Role is required',
    validateRoleNameRequired: 'Role Name is required',
    validateRolloverRequired: 'Rollover is required',
    validateSchemaNameRequired: 'Schema Name is required',
    validateSequenceRequired: 'Sequence is required',
    validateSiteRequired: 'Site is required',
    validateSiteCodeRequired: 'Site Code is required',
    validateStartTimeRequired: 'Start Time is required',
    validateStateRequired: 'State is required',
    validateStatusRequired: 'Status is required',
    validateStopAfterFailureRequired: 'Stop After Failure is required',
    validateSuccess: 'Validate Success',
    validateSupportTypeRequired: 'Support Type is required',
    validateTeamNameEnRequired: 'Team Name English is required',
    validateTeamNameLocalRequired: 'Team Name Local is required',
    validateTelephoneRequired: 'Telephone is required',
    validateThirdSerialNumberRequired: 'Third Serial Number is required',
    validateThreeDigitsNumbeRequired: '3 digits number is required',
    validateTitleRequired: 'Title is required',
    validateTimeTypeRequired: 'Time Type is required',
    validateTriggerTypeRequired: 'Trigger Type is required',
    validateUrlFormat: 'Please enter proper url',
    validateUsernameRequired: 'Username is required',
    validateUsernameSize: 'Username has to be 6-12 characters',
    validateUserTypeRequired: 'User Type is required',
    validateValueRequired: 'Value is required',
    validateVIPRequired: 'VIP is required',
    validateWalletTypeRequired: 'Wallet Type is required',
    validateWayRequired: 'Way is required',
    validateWebMessageRequired: 'Web Message is required',
    validateWholeNumberOnly: 'Please enter whole number only',
    validateWithdrawExchangeRateRequired: 'Withdraw Exchange Rate is required',
    validateWithdrawPlatformNameRequired: 'Withdraw Platform Name is required',
    validateTigerCardPeriodRequired: 'Period required',
    validateTigerCardTimeRequired: 'Lottery Time required',
    validateTigerCardBonusTimeRequired: 'Receive Time required',
    validateTigerSumAwardRequired: 'Total bonus required',
    validateVirtualCountRequired: 'Count is required',
    validateHostRequired: 'Host is required',
    validatePortRequired: 'Port is required',
    validateSignNameRequired: 'Sign Name is required',
    validateSecretIdRequired: 'Secret ID is required',
    validateSecretKeyRequired: 'Secret Key is required',
    validateAppIdRequired: 'Application ID is required',
    validateTemplateIdRequired: 'Template ID is required',
  },
  menu: {
    undefined: '',
    Dashboard: 'Dashboard',
    Member: 'Member',
    'Member List': 'Member List',
    'Member Details': 'Member Details',
    'Member Edit Log': 'Member Edit Log',
    'Member Freeze Record': 'Member Freeze Record',
    'Member Amount Adjust': 'Member Amount Adjust',
    'Member Privilege Blacklist': 'Member Privilege Blacklist',
    'Member Bank Card History': 'Member Bank Card History',
    'Member Sms': 'Member Sms History',
    'Import Bet Records': 'Import Bet Records',
    'Member Transfer Record': 'Member Transfer Record',
    'Member VIP': 'Member VIP',
    Affiliate: 'Affiliate',
    'Affiliate Summary': 'Affiliate Summary',
    'Affiliate Monthly Settlement': 'Affiliate Monthly Settlement',
    'Affiliate Monthly Settlement Detail': 'Affiliate Monthly Settlement Detail',
    'Affiliate Settlement Checking': 'Affiliate Settlement Checking',
    'Affiliate Settlement Payment': 'Affiliate Settlement Payment',
    'Affiliate Domain Management': 'Affiliate Domain Management',
    'Affiliate List': 'Affiliate List',
    'Affiliate Details': 'Affiliate Details',
    'Site Affiliate Commission': 'Site Affiliate Commission',
    'Deposit Management': 'Deposit Management',
    'Online Deposit': 'Online Deposit',
    'Deposit Record': 'Deposit Record',
    'Withdrawal Management': 'Withdrawal Management',
    'Withdrawal Process': 'Withdrawal Process',
    'Withdrawal Affiliate Process': 'Withdrawal Affiliate Process',
    'Withdrawal Record': 'Withdrawal Record',
    'Withdrawal Affiliate Record': 'Withdrawal Affiliate Record',
    'Withdrawal Platform Balance': 'Withdrawal Platform Balance',
    'Withdraw Review': 'Withdrawal Review',
    'Withdrawal Bank Blacklist': 'Withdrawal Bank Blacklist',
    'Withdraw Tips Setting': 'Withdraw Tips Setting',
    'Reward Group': 'Reward Group',
    Applying: 'Applying',
    'Affiliate Applying': 'AffiliateApplying',
    'Affiliate Under review': 'Affiliate Under review',
    'To be affiliate paid': 'To be affiliate paid',
    'Affiliate Payment on going': 'Affiliate Payment on going',
    'Crypto Affiliate Payment on going': 'Crypto Affiliate Payment on going',
    'Ewallt Affiliate Payment on going': 'Ewallet Affiliate Payment on going',
    'Alipay Affiliate Payment on going': 'Alipay Affiliate Payment on going',
    'Affiliate Automatic Payment': 'Affiliate Automatic Payment',
    'Affiliate Suspend': 'Affiliate Suspend',
    'Under review': 'Under review',
    'To be paid': 'To be paid',
    'Payment on going': 'Normal Payment',
    'Crypto Payment on going': 'Crypto Paying',
    'Ewallt Payment on going': 'Ewallet Paying',
    'Alipay Payment on going': 'Alipay Paying',
    'Automatic Payment': 'Automatic Paying',
    Suspend: 'Suspend',
    Promotion: 'Promotion',
    'Privilege Info': 'Privilege Info',
    'Home Banner': 'Home Banner',
    'Promotion pages': 'Promotion pages',
    'Edit Promo': 'Edit Promo',
    'Team Votes': 'Team Votes',
    'Team Votes Records': 'Team Votes Records',
    'Team Votes Settings': 'Team Votes Settings',
    'Tiger Card': 'Tiger Card',
    'Telephone Numbers': 'Telephone Numbers',
    'Ads Popout': 'Ads Popout Page',
    'Add Ads Popout': 'Add Ads Popout',
    'Edit Ads Popout': 'Edit Ads Popout',
    Lottery: 'Lottery',
    'Lottery Record List': 'Lottery Record List',
    'Lottery Result List': 'Lottery Result List',
    'Site Management': 'Site Management',
    Message: 'Message',
    Announcement: 'Announcement',
    'Announcement Type': 'Announcement Type',
    'VIP Rebate': 'VIP Rebate',
    VIP: 'VIP',
    Game: 'Game',
    'Financial Level': 'Financial Level',
    'Risk Level': 'Risk Level',
    'Ip Label': 'IP Label',
    'Site Platform Management': 'Site Platform Management',
    Permission: 'Permission',
    Menu: 'Menu',
    Roles: 'Roles',
    User: 'User',
    Report: 'Report',
    'Privilege Record': 'Privilege Record',
    'Summary Report': 'Summary Report',
    'Privilege Report': 'Privilege Report',
    'Deposit Report': 'Deposit Report',
    'Withdraw Report': 'Withdraw Report',
    'Finance Report': 'Finance Report',
    'Affiliate Report': 'Affiliate Report',
    'Platform Game Report': 'Platform Game Report',
    'Site Member Report': 'Member Report',
    System: 'System',
    'Operator Log': 'Operator Log',
    'Member Platform': 'Member Platform',
    Site: 'Site',
    Config: 'Config',
    Currency: 'Currency',
    Platform: 'Platform',
    'Site Platform': 'Site Platform',
    Schedule: 'Schedule',
    'Platform Account': 'Platform Account',
    'Data Dictionary': 'Data Dictionary',
    'Manual Summary': 'Manual Summary',
    'Affiliate Manual Summary': 'Affiliate Manual Summary',
    'Payment Management': 'Payment Management',
    'Payment Display List': 'Payment Display List',
    'Bank Info': 'Bank Info',
    'Bank Card': 'Bank Card',
    'Payment Type': 'Payment Type',
    Payment: 'Payment',
    'Add Payment': 'Add Payment',
    'Edit Payment': 'Edit Payment',
    CurrencyRate: 'Currency Rate',
    'Payment Display': 'Payment Display',
    'Withdraw Platform': 'Withdraw Platform',
    'Withdraw Setting': 'Withdraw Setting',
    'Site Withdraw Platform': 'Site Withdraw Platform',
    'Deposit Setting': 'Deposit Setting',
    'Withdraw Display': 'Withdraw Display',
    'Payment Bank Management': 'Payment Bank Management',
    'Withdraw Platform Bank Management': 'Withdraw Platform Bank Management',
    'Summary Register Report': 'Summary Register Report',
    'Summary Fdp Report': 'Summary Fdp Report',
    'Summary Deposit Report': 'Summary Deposit Report',
    'Summary Withdraw Report': 'Summary Withdraw Report',
    'Summary Active Report': 'Summary Active Report',
    'Summary Member Report': 'Summary Member Report',
    'Finance Feedback': 'Finance Feedback',
    'Email Setting': 'Email Setting',
    'SMS Setting': 'SMS Setting'
  },
  error: {
    400: 'Bad Request',
    401: 'Unauthorized Request',
    403: 'Forbidden',
    404: 'Resource cannot be found',
    405: 'Method Not Allowed',
    500: 'System Error',
    501: 'Duplicate Request ID',
    502: 'Server Not Found',
    504: 'Too Often Request',
    601: 'Token verification error',
    602: 'Token has expired',
    603: 'Token is missed',
    604: 'Account already login',
    605: 'Account already logged out',
    606: 'The selected role is already assigned to the user, please unassign this role before trying again',
    607: 'Site cannot be empty when user class is not site admin',
    700: 'Old and new password cannot be the same',
    701: 'Parameter already exist',
    707: 'Insufficient balance',
    800: 'Captcha verification error',
    801: 'Captcha code has expired',
    900: 'Member details could not be found',
    901: 'Affiliate record could not be found',
    1000: 'Login failed, username or password is wrong',
    1001: 'Login failed, account is disabled',
    1005: 'Login failed, account has been locked, please try again tomorrow',
    1006: 'Login failed, account does not exists',
    1007: 'The withdrawal order has been submitted, please try again in 20 minutes',
    9000: 'Failed to create scheduled job',
    9001: 'Failed to update scheduled job',
    9002: 'Failed to delete scheduled job',
    10009: 'Enter at least one query condition',
    10010: 'System announcement type name already exist',
    10011: 'System announcement title already exist',
    10012: 'System announcement type does not exist',
    10013: 'Announcement type is being used by existing announcement. Please proceed to delete existing announcement(s)',
    10020: 'Banner title already exist',
    10021: 'Banner ID not found',
    10030: 'Promo title already exist',
    10031: 'Promo ID not found',
    10032: 'Invalid file type for image',
    10033: 'Image banner upload failed',
    10034: 'Image promo upload failed',
    10040: 'System risk level name already exist',
    10041: 'System risk level color already exist',
    10050: 'System ip label already exist',
    10051: 'System ip label does not exist',
    11000: 'affiliate does not exist',
    11006: 'Site affiliate commission already exist',
    11007: 'Site affiliate commission does not exist',
    11008: 'Affiliate record does not exist',
    14001: 'The telephone already exist',
    14005: 'Affiliate commission must be less than superior affiliate commission: ',
    11009: 'Affiliate commission must be more than child affiliate commission: ',
    12000: 'System privilege info name already exist',
    12001: 'System privilege info code already exist',
    12010: 'Currency name already exist',
    12011: 'Currency rate already set for this site',
    12020: "Current record's status is not SENDING",
    12030: 'Site name already exist',
    12031: 'Site code already exist',
    12032: 'This Site is parent site',
    12033: 'Parent Site can not be changed',
    12034: 'Payment name already exist',
    12035: 'Payment code already exist',
    12036: 'System privilege info does not exists',
    12037: 'System payment id does not exist',
    12038: 'The amount is greater than the max bonus',
    12039: 'The member is already blacklisted to the privilege',
    12040: 'System payment name does not exists',
    12041: 'Mall Name already exist',
    12042: 'Mall Code already exist',
    12050: 'System bank does not exists',
    12051: 'System bank name already exist',
    12052: 'System bank code already exist',
    12053: 'System bank card identify code exists',
    12054: 'System bank card already exist',
    14110: 'Platform and Site with same name already exist',
    14111: 'The status must be in CLOSE for one month before delete',
    14112: 'The status of this Site Platform in CLOSE is less than one month, please delete after one month',
    14120: 'Financial Level, Currency, Site, and device with same name already exist',
    14121: 'Minimum withdraw amount must not less than 0',
    14122: 'Maximum withdraw amount must not less than 0',
    14123: 'Maximum withdraw times must not less than 0',
    14124: 'Daily maximum withdraw amount must not less than 0',
    14125: 'Minimum withdraw amount must not be greater than maximum amount',
    14126: 'Maximum withdraw amount must not be lesser than minimum amount',
    14127: 'Daily maximum withdraw amount must not be lesser than maximum withdraw amount',
    14130: 'Platform Account does not exist',
    14131: 'Platform Account being used by other Site Platform',
    15000: 'VIP name already exist on the site',
    15001: 'VIP does not exist',
    15002: 'VIP is being used by existing VIP, it cannot be deleted',
    15003: 'VIP rebate rule exists for given VIP level and game type',
    15004: 'VIP rebate rule does not exist',
    15010: 'Financial level name already exist on the site',
    15012: 'Financial level does not exist',
    15020: 'Deposit Record does not exist',
    15021: 'Financial Level, Pay Type, Site, and Way with same name already exist',
    15030: 'Unable to process the JSON data',
    15040: 'Withdraw Dsiplay name already exist',
    15041: 'Withdraw Dsiplay does not exist',
    15050: 'Bank already exists in given payment',
    15051: 'Code already exists in given payment',
    15060: 'Withdraw platform id does not exist',
    15061: 'Bank already exists in given withdraw platform',
    15062: 'Code already exists in given withdraw platform',
    15063: 'Withdraw record does not exist',
    15064: 'Withdraw platform does not exist',
    15065: 'Withdraw card does not exist',
    16000: '3 digit number is required',
    16001: 'Number should be 3 digits',
    16002: 'Lottery result has been announced for the selected date',
    16010: 'English team name exists',
    16011: 'Team does not exist',
    17010: 'Vote record does not exist',
    17011: 'Vote record is already cancelled',
    18000: 'Domain already exists',
    18001: 'Affiliate domain does not exist',
    19000: 'Bank card already blacklisted',
    19101: 'Ads title already exist.',
    19102: 'Ads ID not found.',
    19103: 'Ads for the site already turn on, please turn off existing ads',
    20000: 'SMS Setting already exist on the site',
    20100: 'Email Setting already exist on the site',
    20201: 'Wrong type',
    20202: 'Please insert',
    20203: 'Birthday format error (yyyy-MM-dd)',
    20204: 'Risk level not found, please insert correct risk level(eg：1)',
    20205: 'Financial level not found，please insert correct financial level (eg：1)',
    20206: 'Please insert',
  },
}
