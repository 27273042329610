export default {
  common: {
    title: 'TF仪表板',
    logout: '登出',
    changePassword: '更换密码',
  },
  affiliate: {
    commissionModel: {
      NORMAL: '正常',
      SIMPLE: '普通'
    },
    level: {
      AFFILIATE: 'Affiliate',
      SUPER_AFFILIATE: 'Super Affiliate',
      MASTER_AFFILIATE: 'Master Affiliate',
      CHIEF_AFFILIATE: 'Chief Affiliate'
    },
    timeType: {
      MONTHLY: '每月',
      WEEKLY: '每周'
    },
    status: {
      APPLY: '申请',
      NORMAL: '正常',
      DISABLE: '禁用'
    }
  },
  types: {
    NORMAL: '正常',
    TEMPORARY: '临时冻结',
    PERMANENT: '永久冻结',
    gameViolation: '违规游戏',
    memberRequest: '会员要求',
    others: '其他',
    TEST: '测试',
    AFFILIATE: '代理',
    OUTSIDE: '三方',
  },
  dateType: {
    depositDate: '存款日期',
    finishDate: '完成日期',
    withdrawDate: '提款日期',
    paymentDate: '支付日期'
  },
  reportName: {
    Aff_Month_Report: '代理月报表',
    Deposit_Record: '存款记录',
    Withdraw_Record: '提款记录',
    Privilege_Record: '优惠记录',
    Finance_Report: '财务记录',
    Summary_Record: '总结记录',
    Summary_Active_Record: '总结活跃记录',
    Summary_Register_Record: '总结注册记录',
    Summary_Fdp_Record: '总结Fdp记录',
    Summary_Withdraw_Record: '总结提款记录',
    Summary_Deposit_Record: '总结存款记录',
    Summary_Member_Record: '总结会员记录',
  },
  depositStatus: {
    SUCCESS: '存款成功',
    SUPPLEMENT_SUCCESS: '存款补单成功',
    CLOSED: '已关闭',
    PENDING: '待存款'
  },
  withdrawStatus: {
    ALL: '全部',
    SUCCESS: '支付成功',
    FAIL: '支付失败',
    APPLY: '申请中',
    STEP_1: '审核中',
    STEP_2: '待支付',
    STEP_3: '支付中',
    AUTOPAY: '自动支付中',
    PENDING: '中止',
    REJECT: '驳回',
    SENDING: '发送中',
    WAITING_CALLBACK: '自动支付中',
    PAYING: '支付'
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  editCheckedStatus: {
    0: '未审核',
    1: '已更改',
    2: '审核失败',
  },
  transfer: {
    status: {
      sending: '发送中',
      success: '成功',
      fail: '失败'
    },
    type: {
      deposit: '转入',
      withdraw: '转出'
    }
  },
  moneyChange: {
    type: {
      DEPOSIT: '存款',
      WITHDRAW: '提款',
      PROMO: '优惠',
      TRANSFER: '转账',
      BET: '投注',
      WITHDRAW_FAIL: '提款失败',
      ADJUST: '账变',
      PAYOUT: '派彩',
      ROLLBACK: '回滚',
      PATCH: '修补',
      BET_N_PAYOUT: '投注并派彩',
      AFFILIATE_TRANSFER: '代理代存'
    },
    subType: {
      DEPOSIT: '主账号转入平台',
      WITHDRAW: '平台转出主账号',
      TRASNFER_IN: '转入',
      TRANSFER_OUT: '转出',
      AFFILIATE_SETTLEMENT: '代理结算'
    }
  },
  status: {
    monthSummary: {
      UNCLEAR: '未结算',
      CLEARING: '结算中',
      CLEARED: '已结算'
    },
    settlement: {
      CHECKING: '审核中',
      PAY: '结算中',
      CLEARED: '已结算'
    },
    member: {
      NORMAL: '正常',
      FROZEN: '冻结'
    }
  },
  dashboard: {
    memberCount: '人数',
    totalFirstDeposit: '总首存',
    totalDeposit: '总存款',
    totalTransaction: '笔数',
    totalTransfer: '总代充',
    totalWithdraw: '总提款',
    totalWinLoss: '总输赢',
    totalValidBet: '总有效投注',
    totalBonus: '总彩金',
    returnAmount: '返水金额',
    totalActiveMember: '总活跃会员',
    newRegisterMember: '新注册会员',
    totalVisit: '总访问量',
    depositChartName: '存款总金额',
    depositAmount: '存款金额',
    depositMemberCount: '存款人数',
    depositTransactionCount: '存款笔数',
    depositTransactionNumChartName: '存款总人数/存款总笔数',
    financialLevelRatio: '财务层次占比',
    vipLevelRatio: 'VIP等级占比',
    paymentMethod: '通道类别',
    ratio: '占比',
    fundAmount: '资金量',
    paymentMethodSummary: '支付方式充值汇总',
    total: '合计',
    bet: '投注',
    payout: '结算',
    merchantDepositSummary: '商户充值汇总',
  },
  reportGame: {
    gamePlatform: '游戏平台',
    gameMemberCount: '每日游戏人数',
    gameBetCount: '每日下注笔数',
    gameBetAmount: '每日下注金额',
    gameWinLoss: '每日输/赢金额',
    gameMemberCountTotal: '总游戏人数',
    gameBetCountTotal: '总下注笔数',
    gameBetAmountTotal: '总下注金额',
    gameWinLossTotal: '总输/赢金额',
  },
  fields: {
    account: '账号',
    accountHolder: '账号持有者',
    accountInfo: '账号信息',
    accountNumber: '账户号码',
    accountStatus: '账号状态',
    action: '操作',
    activeMember: '活跃会员',
    activeMemberCount: '活跃会员数',
    activePlayer: '活跃玩家',
    activeUser: '活跃会员',
    add: '新增',
    addAds: '新增弹窗广告',
    addAffiliate: '新增代理',
    addAffiliateCommission: '新增代理分红',
    addAffiliateDomain: '新增代理域名',
    addAnnouncement: '新增公告',
    addAnnouncementType: '新增公告类型',
    addBank: '新增银行',
    addBankCard: '新增银行卡',
    addBankToPayment: '新增银行至支付',
    addBankToWithdrawPlatform: '新增银行至提款平台',
    addBanner: '新增广告',
    addRewardGroup: '新增奖金组',
    addCurrency: '新增货币',
    addCurrencyRate: '新增汇率',
    addDepositSetting: '新增存款设置',
    addDomain: '新增域名',
    addEmail: '新增邮件',
    addFinancialLevel: '新增会员组别',
    addGame: '新增游戏',
    addIpLabel: '新增 IP 标签',
    addJob: '新增任务',
    addLotteryResult: '新增彩票结果',
    addMember: '新增会员',
    addMemberAmountAdjust: '新增会员平账',
    addMemberPrivilegeBlacklist: '新增会员优惠黑名单',
    addMenu: '新增菜单',
    addNodes: '新增节点',
    addPayType: '新增支付类型',
    addPlatform: '新增平台',
    addPlatformAccount: '新增平台账号',
    addPrivilegeInfo: '新增优惠信息',
    addRecipient: '新增收件人',
    addRemark: '新增备注',
    addRiskLevel: '新增风险等级',
    addRole: '新增角色',
    addSite: '新增站点',
    addSitePlatform: '新增平台站点',
    addSystemMessage: '新增系统信息',
    addTelephone: '新增手机号码',
    addUser: '新增用户',
    addVip: '新增VIP',
    addVipRebateRule: '新增VIP返水条例',
    addWithdrawPlatform: '新增提款平台',
    addWithdrawSetting: '新增提款设置',
    addWithdrawalBankBlacklist: '新增提款银行卡黑名单',
    adjustAmount: '平账金额',
    adjustBy: '平账操作者',
    adjustReason: '平账原因',
    adjustTime: '平账时间',
    adjustType: '平账类型',
    adjustment: '调整金额',
    adjustmentType: '调整类型',
    affiliate: '代理',
    affiliateBankCard: '代理银行卡',
    affiliateBankCardRecord: '代理银行卡记录',
    affiliateCode: '代理代码',
    affiliateDomainList: '代理域名列表',
    affiliateInfo: '代理信息',
    affiliateLevel: '代理级别',
    affiliateLink: '代理链接',
    affiliateList: '代理列表',
    affiliateName: '代理名',
    affiliateStatus: '代理状态',
    affiliateTransferAmount: '代存金额',
    affiliateTransferRecord: '代理代存记录',
    advancedSearch: '高级搜索',
    afterAmount: '之后金额',
    afterBalance: '之后余额',
    afterEdit: '编辑后',
    allvip: '全部VIP',
    allfinanciallevel: '全部组别',
    allprofit: '全部盈亏',
    allreviewstatus: '全部复核状态',
    amount: '金额',
    adjust: '平账',
    announcementType: '公告类型',
    applying: '申请中',
    approve: '批准',
    assigned: '已分配',
    assignment: '分配',
    auditTime: '审计时间',
    automaticPayment: '自动支付',
    autopay: '自动支付',
    automaticPay: '自动支付',
    back: '返回',
    balance: '余额',
    balanceUrl: '余额地址',
    bank: '银行',
    bankAccount: '银行账号',
    bankCard: '银行卡',
    bankCardNumber: '银行卡号码',
    bankCode: '银行代码',
    bankName: '银行名',
    bankNumber: '银行号码',
    bankType: '银行类型',
    beanName: '对象名称',
    beforeAmount: '之前金额',
    beforeBalance: '之前余额',
    beforeEdit: '编辑前',
    bet: '下注金额',
    betId: '投注单号',
    betMoreThan: '投注金额大于',
    betRecords: '投注记录',
    betStatus: '投注状态',
    betTime: '投注时间',
    birthday: '生日',
    bindTypeAll: '全部',
    bindTypeNew: '新增',
    bindTypeRemove: '解绑',
    bonus: '奖金',
    bonusAmount: '奖金金额',
    bonusReceived: '已领取奖金',
    bonusType: '奖金类型',
    byprofitasc: '负盈利低到高',
    byprofitdesc: '负盈利高到低',
    bydateasc: '日期高到低',
    bydatedesc: '日期低到高',
    callbackUrl: '回调地址',
    cancel: '取消',
    cancelDeposit: '取消存款',
    cancelRemark: '取消备注',
    cancelUpdate: '取消更新',
    cancelType: '取消类型',
    cardAccount: '银行卡账号',
    cardAddress: '银行卡地址',
    cardNumber: '银行卡号码',
    cardTime: '操作日期',
    cardTypeAll: '全部',
    cardTypeBank: '银行',
    cardTypeWallet: '电子钱包',
    cardTypeCrypto: '加密货币',
    category: '类别',
    cause: '原因',
    changeAffiliate: '更换代理',
    check: '审核中',
    checkBy: '审核人',
    checkDate: '审核日期',
    checkExclusiveUrl: '查看专属网址',
    checkFail: '审核失败',
    checkTime: '审核时长',
    className: '类别名',
    clearingSum: '结算总和',
    clientType: '客户类型',
    close: '关闭',
    code: '代码',
    color: '颜色',
    commission: '分红',
    commissionModel: '分红模式',
    commissionPercent: '分红 %',
    commissionRate: '分红比率',
    componentName: '组件名称',
    componentPath: '组件路径',
    composeMessage: '撰写邮件',
    consumingTime: '耗费时间',
    commitTime: '订单日期',
    companyProfit: '公司盈利',
    confirm: '确认',
    confirmAndImport: '确认并导入',
    confirmNewPassword: '确认新密码',
    confirmPassword: '确认密码',
    confirmPay: '确认结算',
    confirmStatus: '确认状态',
    confirmBy: '确认人',
    content: '内容',
    convertRate: '转化率',
    copy: '复制',
    copyPayment: '正在复制',
    copyTo: '复制到',
    copyToOtherLevel: '复制到其他会员组别',
    country: '国家',
    countryImage: '国家图像',
    createBy: '创建人',
    createTime: '创建时间',
    createVote: '创建投票',
    cronExpression: 'Cron表达式',
    currency: '货币',
    currencyCode: '货币代码',
    currencyName: '货币名称',
    currencyRate: '汇率',
    currentPassword: '当前密码',
    customerSupportAddress: '客服地址',
    dailyDeposit: '每日存款数量',
    dailyDepositAmount: '每日存款金额',
    dailyWithdraw: '每日提款数量',
    dailyWithdrawAmount: '每日提款金额',
    dailyFailAmount: '每日失败存款金额',
    dailyFailDeposit: '每日失败存款数量',
    dailyFailWithdraw: '每日失败提款数量',
    dailyMemberFailDeposit: '每日用户失败存款数量',
    dailyMemberFailWithdraw: '每日用户失败提款数量',
    dailySuccessAmount: '每日成功存款金额',
    dailySuccessDeposit: '每日成功存款数量',
    dailySuccessWithdraw: '每日成功提款数量',
    dailyMemberSuccessDeposit: '每日用户成功存款数量',
    dailyMemberSuccessWithdraw: '每日用户成功提款数量',
    dailySuccessPercentage: '每日成功存款比率',
    dailyTotalAmount: '每日总金额',
    dailyTotalRedeem: '每日总领取次数',
    dayWithdrawCount: '每日提款次数',
    date: '日期',
    dateReceived: '领取日期',
    dateType: '日期类型',
    deduct: '扣除',
    deductMemberAmountAdjust: '扣除会员平账',
    defaultFinancialLevel: '默认会员组别',
    defaultRiskLevel: '默认风险等级',
    defaultVip: '默认VIP',
    delete: '删除',
    deleteRole: '删除角色',
    deposit: '存款',
    depositAmount: '存款金额',
    depositBettingAmount: '存款/下注金额',
    depositCount: '存款次数',
    depositDate: '存款日期',
    depositExchangeRate: '存款兑换率',
    depositMemberCount: '存款人数',
    depositSerialNo: '存款流水号',
    depositStatus: '存款状态',
    describe: '描述',
    description: '描述',
    desktopImage: '桌面图像',
    desktopBanner: '桌面广告图',
    details: '信息',
    device: '设备',
    dif: '存提差',
    disable: '禁用',
    disableAffiliate: '禁用代理',
    disableType: '禁用类型',
    distributePrivilege: '发放奖金',
    domain: '域名',
    domainCount: '域名数量',
    domainList: '域名列表',
    done: '完成',
    downgradeToApplying: '降级至申请中',
    downgradeToWaitPay: '降级至待支付',
    downlineAffiliate: '直属代理',
    downlineCommission: '下级分红',
    downlineCommissionRate: '下级分红比率',
    downlineMember: '直属会员',
    downlineProfit: '下级利润',
    downloadTemplate: '下载模板',
    edit: '编辑',
    editAds: '编辑弹窗广告',
    editAffiliateCommission: '编辑代理分红',
    editAffiliateDomain: '编辑代理域名',
    editAmount: '编辑金额',
    editAnnouncement: '编辑公告',
    editAnnouncementType: '编辑公告类型',
    editBank: '编辑银行',
    editBankCard: '编辑银行卡',
    editBanner: '编辑广告',
    editCheck: '更改',
    editCurrency: '编辑货币',
    editCurrencyRate: '编辑汇率',
    editDepositSetting: '编辑存款设置',
    editDetails: '编辑信息',
    editEmail: '编辑邮件',
    editFinancialLevel: '编辑会员组别',
    editGame: '编辑游戏',
    editIpLabel: '编辑 IP 标签',
    editJob: '编辑任务',
    editMenu: '编辑菜单',
    editPayType: '编辑支付类型',
    editPlatform: '编辑平台',
    editPlatformAccount: '编辑平台账号',
    editPrivilegeInfo: '编辑优惠信息',
    editRemark: '编辑备注',
    editRewardGroup: '编辑奖金组',
    editRiskLevel: '编辑风险等级',
    editRole: '编辑角色',
    editSite: '编辑站点',
    editSitePlatform: '编辑平台站点',
    editTelephone: '编辑手机号码',
    editTimeType: '编辑时间类型',
    editType: '编辑类型',
    editUser: '编辑用户',
    editVip: '编辑VIP',
    editVipRebateRule: '编辑VIP返水条例',
    editWithdrawPlatform: '编辑提款平台',
    editWithdrawSetting: '编辑提款设置',
    email: '邮箱',
    endDate: '结束日期',
    endTime: '结束时间',
    exclusiveUrlWeb: '专属网址（网页版）',
    exclusiveUrlWap: '专属网址（手机版）',
    exclusiveUrlApp: '专属后台网址',
    exclusiveUrlCashier: '专属后台网址',
    exclusiveUrl: '专属网址',
    exchangeRate: '兑换率',
    exportToExcel: '导出至Excel',
    extraParam: '扩展信息',
    fail: '失败',
    failReason: '失败原因',
    features: '特性',
    feedbackType: '催单类型',
    feedbackTypeAll: '全部',
    feedbackTypeDeposit: '存款',
    feedbackTypeWithdraw: '提款',
    feedbackStatus: '催单状态',
    feedbackStatusAll: '全部',
    feedbackStatusPending: '核实中',
    feedbackStatusSuccess: '已核实',
    feedbackTime: '催单日期',
    feeRate: '收费率',
    finalSum: '最终收入',
    financialLevel: '会员组别',
    financePhotos: '图片',
    financeType: '存/提',
    financeRemark: '财务备注',
    financeStatus: '状态',
    finishDate: '完成日期',
    firstDeposit: '首存用户',
    firstDepositMemberCount: '首存人数',
    firstDepositMember: '首存用户',
    freeze: '冻结',
    freezeMember: '冻结会员',
    freezeType: '冻结类型',
    frequency: '频率',
    ftd: '首存',
    ftdAmount: '首存金额',
    ftdAverage: '人均首存',
    ftdCount: '首存人数',
    ftdTime: '首存时间',
    ftdTxn: '首存交易',
    fundingInfo: '资金信息',
    gameCode: '游戏代码',
    gameName: '游戏名字',
    gameType: '游戏类型',
    generateSummary: '生成汇总',
    hasPromo: '有优惠',
    hidden: '隐藏',
    icon: '图标',
    id: 'ID',
    identifyCode: '识别码',
    import: '导入',
    inbox: '收件箱',
    ip: '访问 IP',
    itsYou: '是你',
    jobName: '任务名称',
    label: '标签',
    lastLogin: '最近登录',
    lastLoginAddress: '最近登录地址',
    lastLoginIp: '最近登录IP',
    lastLoginTime: '最近登录时间',
    lastMonth: '上月',
    last3Months: '上3个月',
    last6Months: '上6个月',
    lastWeek: '上周',
    level: '级别',
    levelUpCredit: '升级金额',
    localCurrencyAmount: '当地货币金额',
    lockStatus: '锁定状态',
    login: '登录',
    loginAddress: '登录地址',
    loginFailedReason: '登录失败原因',
    loginInfo: '登录信息',
    loginIp: '登录IP',
    loginName: '登录名',
    loginNameSeparateComma: '登录名（以逗号来区分）',
    loginStatus: '登录状态',
    loginTime: '登录时间',
    loginUrl: '登录网址',
    loginWay: '登录方式',
    logoutPlayer: '登出用户',
    lotteryRecordList: '彩票记录',
    lotteryResultList: '彩票结果',
    mallCode: '商户号',
    mallExtraKey: '扩展秘钥',
    mallKey: '秘钥',
    mallName: '支付编码',
    manualPay: '手动支付',
    massImport: '批量导入',
    maxBonus: '最高奖金',
    maxDailyWithdraw: '每日最高提款金额',
    maxDailyWithdrawTimes: '每日最多提款次数',
    maxDeposit: '最高存款',
    maxWithdrawAmount: '最高提款金额',
    member: '会员',
    memberBankCard: '会员银行卡',
    memberBankCardRecord: '会员银行卡记录',
    memberBetRecord: '会员投注记录',
    memberCommission: '会员分红',
    memberId: '会员ID',
    memberInfo: '会员信息',
    memberList: '会员列表',
    memberName: '会员名',
    memberPlatformAction: '操作（手动注册/删除）',
    memberPlatformUpdate: '同步密码',
    memberPrivilegeBlacklist: '会员优惠黑名单',
    memberRemark: '玩家备注',
    memberReport: '会员报表',
    memberType: '会员类型',
    memberWithdrawLog: '会员提款日志',
    menuGame: '菜单游戏序列',
    menuIcon: '菜单图标',
    menuTitle: '菜单标题',
    menuType: '菜单类型',
    message: '信息',
    min: '最低盈亏金额',
    max: '最高盈亏金额',
    minno: '请输入最低金额',
    maxno: '请输入最高金额',
    minActiveMemberCount: '最低活跃会员数',
    minDeposit: '最低存款',
    minTotalCommission: '最低总分红',
    minWithdrawAmount: '最低提款金额',
    mobile: '手机版',
    mobileImage: '手机图像',
    mobileBanner: '手机广告图',
    mobileMessage: '手机提示信息',
    mobileWay: '移动端',
    moneyChange: '账变',
    month: '月份',
    name: '名称',
    needRegister: '需要注册',
    netProfit: '净利润',
    newMember: '新会员',
    newMemberCount: '新会员数',
    newPassword: '新密码',
    nextActivationTime: '下次启动时间',
    nextGetBetEndTime: '下次取投注记录结束时间',
    nextGetBetIndex: '下次取投注记录指数',
    nextGetBetPage: '下次取投注记录页面',
    nextGetBetStartTime: '下次取投注记录开始时间',
    nextLevel: '晋级',
    nextLevelPoint: '晋级点数',
    noData: '查无数据',
    noOfDeduction: '扣除总数',
    noOfDepositTimes: '存款总数',
    noOfPrivilegeClaimed: '已领取优惠总数',
    noOfReimbursement: '报销总数',
    noOfWinners: '得奖人数',
    noOfWithdrawTimes: '提款总数',
    notice: '注意',
    notRegister: '未注册',
    open: '开启',
    operate: '操作',
    operator: '操作人',
    operateTime: '操作时间',
    operationTime: '操作时间',
    operationType: '操作类型',
    orderNo: '订单号',
    param: '参数',
    parentSite: '上级站点',
    password: '密码',
    pause: '暂停',
    pay: '支付',
    payBy: '支付人',
    payMessage: '支付信息',
    payMessageMobile: '手机支付信息',
    payResultType: '支付结果显示方式',
    payTime: '支付时长',
    payType: '支付类型',
    payTypeCode: '支付代码',
    payTypeName: '支付名称',
    payment: '支付',
    paymentArea: '支付区',
    paymentBy: '支付人',
    paymentCard: '支付卡',
    paymentDate: '支付日期',
    paymentMethod: '支付方式',
    paymentName: '支付名称',
    paymentOnGoing: '支付中',
    paymentType: '支付类型',
    payout: '派彩',
    pcWay: 'PC端',
    pending: '中止',
    permission: '权限',
    permissionAssignment: '分配权限',
    platform: '平台',
    platformAccount: '平台账号',
    platformId: '平台 ID',
    platformBalance: '平台余额',
    platformCode: '平台代码',
    platformFundTransfer: '平台转账',
    platformName: '平台名',
    pleaseChoose: '请选择',
    pleaseInput: '请输入',
    previousLevel: '上级',
    previousLevelName: '上级名称',
    privilege: '优惠',
    privilegeCode: '优惠代码',
    privilegeId: '优惠 ID',
    privilegeName: '优惠名',
    privilegeRedeemed: '优惠已领取',
    privilegeType: '优惠类型',
    profit: '利润',
    profitpositive: '正数',
    profitnegative: '负数',
    promo: '优惠',
    promoCode: '优惠代码',
    promoPages: '优惠页面',
    promoStyle: '优惠方式',
    promoType: '优惠类型',
    queryNumber: '搜索数量',
    queryOption: '搜索选项',
    queryRestriction: '搜索限制',
    rate: '比率',
    realName: '真实姓名',
    englishName: '英文姓名',
    realVirtualVotes: '真实+虚拟投票',
    reason: '原因',
    reasonType: '原因类型',
    reasonTemplate: '原因模板',
    rebate: '返点',
    rebatePercentage: '返水比例 %',
    maxRebate: '最大返水额',
    receiveTime: '领取时间',
    receiveType: '领取模式',
    recipient: '收件人',
    recordDate: '记录日期',
    recordTime: '记录时间',
    redeemedBy: '领取人',
    redirect: '域名转址',
    reenterPassword: '重新输入密码',
    referrer: '推荐人',
    registered: '已注册',
    register: '新注册用户',
    registerAddress: '注册地址',
    registerCount: '新注册用户人数',
    registerDevice: '注册装置',
    registerHost: '注册域名',
    registerIp: '注册IP',
    registerMember: '新注册用户',
    registerTime: '注册时间',
    registrationDate: '注册日期',
    referredFriends: '已推荐好友',
    remark: '备注',
    remove: '删除',
    reply: '回复',
    requestUrl: '请求地址',
    requestParam: '请求参数',
    responseBody: '返回参数',
    reset: '重置',
    result: '结果',
    resultDate: '开彩日期',
    resultNumber: '开彩号码',
    resultTime: '开彩时间',
    resume: '繼續申請中',
    revenueShare: '收入',
    review: '复查',
    reviewby: '操作人',
    reviewno: '未复核',
    reviewsuccess: '正确',
    reviewstatus: '复核状态',
    reviewfail: '错误',
    rewardGroup: '奖金组',
    rewardGroupDownlineQuota: '最高下线人数',
    rewardGroupName: '奖金组名称',
    riskControl: '风险管理',
    riskLevel: '风险等级',
    role: '角色',
    roleList: '角色列表',
    roleName: '角色名称',
    rollover: '倍数',
    router: '路由',
    ruleParam: '固定金额数组',
    ruleType: '规则类型',
    run: '启动',
    save: '储存',
    saveAsNewPayment: '另存为新支付',
    schemaName: '模式名',
    search: '搜索',
    searchCondition: '搜索条件',
    sendTime: '发送时间',
    select: '选择',
    selectNodeAddTo: '选择节点添加至',
    selected: '已选择',
    selectedNumber: '已选号码',
    send: '发送',
    sequence: '排序',
    serialNo: '流水号',
    settlement: '结算',
    settleTime: '结算时间',
    show: '显示',
    sid: 'SID',
    site: '站点',
    siteCode: '站点代码',
    siteList: '站点列表',
    siteName: '站点名称',
    sn: '序',
    smsPhone: '电话号码',
    smsContent: '内容',
    smsStatus: '发送状态',
    smsSendTime: '发送时间',
    smsType: '短信类型',
    sorting: '排序',
    sourceType: '来源类型',
    sportBetResult: '体育投注结果',
    state: '状态',
    startCopy: '开始复制',
    startUpdate: '开始更新',
    startDate: '开始日期',
    startTime: '开始时间',
    status: '状态',
    stopAfterFailure: '失败后停止',
    subject: '标题',
    subSiteCount: '子站点数量',
    subtotal: '小计',
    success: '成功',
    successfullyExport: '成功导出',
    summaryAdjustment: '账户调整',
    summaryBonus: '红利',
    summaryProfit: '公司盈利',
    summaryReport: '总结报表',
    summaryTotalBet: '投注人数',
    superiorAffiliateInfo: '上级代理信息',
    superiorAffiliateName: '上级代理名',
    superiorAffiliateCode: '上级代理代码',
    superiorAffiliateLevel: '上级代理级别',
    superiorCategory: '上级组件',
    supplement: '补单',
    supplementDeposit: '存款补单',
    supportType: '支持类型',
    suspend: '中止',
    switch: '转换',
    switchPayment: '支付转换',
    systemPayment: '系统支付',
    teamName: '团队名称',
    teamNameEn: '团队英文名称',
    teamNameLocal: '团队当地名称',
    telephone: '电话号码',
    thirdParty: '第三方',
    thirdPartyName: '第三方名称',
    thirdSerialNo: '第三方流水号',
    thisMonth: '本月',
    thisWeek: '本周',
    tips: '提示',
    title: '标题',
    toApplying: '至申请中',
    toBeforePaid: '至待支付',
    toBePaid: '待支付',
    toChecking: '至待审核',
    today: '今日',
    toPay: '至待结算',
    toPaymentOnGoing: '至支付中',
    toreview: '正在复查',
    toSuspend: '至中止',
    total: '总计',
    totalAdjustment: '总调整金额',
    totalBalance: '总余额',
    totalBet: '总投注',
    totalBetRecords: '总投注记录',
    totalBonus: '总奖金',
    totalCommission: '总分红',
    totalCompanyProfit: '总公司盈利',
    totalDeductAmount: '总扣除金额',
    totalDeposit: '总存款',
    totalDepositAmount: '存款总额',
    totalDepositCount: '总存款次数',
    totalDepositedAmount: '存款总额',
    totalDownlineAffiliates: '总直属代理',
    totalDownlineMembers: '总直属会员',
    totalFailDeposit: '存款失败数量',
    totalFailDepositAmount: '存款失败总额',
    totalFailWithdraw: '提款失败数量',
    totalFailWithdrawAmount: '提款失败总额',
    totalFirstDeposit: '总首存用户',
    totalNoOfDeposits: '存款总数',
    totalNoOfWithdrawals: '提款总数',
    totalPayout: '总派彩',
    totalPromo: '总优惠',
    totalProfit: '总利润',
    totalPrivilegeAmount: '总优惠金额',
    totalPrivilegeClaimAmount: '总优惠领取金额',
    totalRedeem: '总领取金额',
    totalRegister: '总新注册用户',
    totalReimburseAmount: '总报销金额',
    totalSuccessDeposit: '存款成功数量',
    totalSuccessDepositAmount: '存款成功总额',
    totalSuccessWithdraw: '提款成功数量',
    totalSuccessWithdrawAmount: '提款成功总额',
    totalTime: '总时长',
    totalTransferIn: '总转入',
    totalTransferOut: '总转出',
    toUnderReview: '至审核中',
    totalWithdraw: '总提款',
    totalWithdrawAmount: '提款总额',
    totalWithdrawCount: '总提款次数',
    totalWithdrawnAmount: '提款总额',
    transaction: '转账信息',
    transactionTime: '交易时间',
    transactionId: '交易单号',
    transferDate: '转账日期',
    transferId: '转账ID',
    transferIn: '转入',
    transferOut: '转出',
    transferTime: '转账时间',
    transferType: '转账类型',
    triggerType: '触发类型',
    turnoverMultiple: '流水倍数',
    txnId: '交易ID',
    txnTime: '交易时间',
    type: '类型',
    underReview: '审核中',
    unlockMember: '解锁账号',
    unlockUser: '解锁用户',
    url: '访问 URL',
    update: '更新',
    updateBy: '更新人',
    updateCommissionModel: '更新分红模式',
    updateCommissionRate: '更新分红比率',
    updateFinancialLevel: '更新会员组别',
    updatePassword: '更新密码',
    updateRiskLevel: '更新风险等级',
    updateTime: '更新时间',
    updateVIPLevel: '更新VIP等级',
    upgradeToCheck: '升级至审核中',
    upgradeToPaymentOnGoing: '升级至支付中',
    upload: '上传',
    username: '用户名',
    userType: '用户类型',
    validate: '验证',
    validBet: '有效投注额',
    value: '值',
    view: '查看',
    viewLog: '查看日志',
    vipLevel: 'VIP等级',
    virtualVotes: '虚拟投票',
    visitNumber: '访问量',
    voteTime: '投票时间',
    walletType: '钱包类型',
    way: '设备',
    web: '电脑版',
    webMessage: '网页提示信息',
    withdraw: '提款',
    withdrawArea: '提款区域',
    withdrawExchangeRate: '提款兑换率',
    withdrawalFailureCause: '提款失败原因',
    withdrawalFailureType: '提款失败类型',
    withdrawAmount: '提款金额',
    withdrawCard: '提款卡',
    withdrawCode: '提款平台代码',
    withdrawCount: '提款次数',
    withdrawDate: '提款日期',
    withdrawMemberCount: '提款人数',
    withdrawName: '提款平台名称',
    withdrawPayoutBonus: '提款/派彩/奖金',
    withdrawPlatform: '提款平台',
    withdrawPlatformList: '提款平台列表',
    withdrawPlatformName: '提款平台名称',
    withdrawStatus: '提款状态',
    yesterday: '昨日',
    tigerCardType: '卡片类型',
    tigerCardOpeType: '操作方式',
    tigerCardSource: '虎卡来源/去向',
    tigerCardBeforeCount: '操作前卡片数量',
    tigerCardRealCount: '真实大奖卡数量',
    tigerCardVirtualCount: '虚拟大奖卡数量',
    tigerCardPeriod: '期数',
    tigerCardTime: '抽卡时间',
    tigerCardBonusTime: '发奖时间',
    timeType: '时间类型',
    count: '数量',
    host: '域名',
    port: '端口',
    user: '用户',
    from: '从',
    addEmailSetting: '新增邮件设置',
    editEmailSetting: '编辑邮件设置',
    signName: '公司名字',
    secretId: '密码',
    secretKey: '密钥',
    appId: '应用ID',
    templateId: '模板ID',
    addSmsSetting: '新增短信设置',
    editSmsSetting: '编辑短信设置',
  },
  message: {
    addRemarkSuccess: '新增备注成功',
    addSuccess: '新增成功',
    adjustSuccess: '平账成功',
    affiliateApproved: '代理已批准',
    affiliateDisabled: '代理已被禁用',
    autopaySuccess: '自动支付成功',
    cancelDepositSuccess: '取消存款成功',
    changeAffiliateSuccess: '更换代理成功',
    changeSuccess: '更改成功',
    confirmCopy: '请确认是否将',
    confirmCopyTo: '复制至',
    confirmDelete: '请确认是否要删除这些数据, 此操作不可撤销',
    confirmSettlement: '请确认是否结算?',
    confirmToChecking: '请确认是否将此数据返回至待审核?',
    confirmToPay: '请确认是否结算更新至待结算?',
    confirmToCheck: '请确认是否更改用户资料',
    confirmUpdate: '请确认是否更新?',
    confirmUpdatePayment: '请确认是否更新支付?',
    copyToClipboard: '已复制到剪贴板中',
    deleteSuccess: '删除成功',
    depositSuccess: '存款成功',
    editAmountSuccess: '编辑金额成功',
    editRemarkSuccess: '编辑备注成功',
    editSuccess: '编辑成功',
    editMemberDetail: '编辑会员信息',
    enterMemberDetails: '输入会员信息',
    failedToUploadImage: '上传图像失败',
    freezeMemberSuccess: '冻结用户成功',
    found: '已找到',
    importSuccess: '导入成功',
    inputDigits: '请输入数字',
    inputPassword: '请输入密码',
    inputPasswordAgain: '请再输入密码',
    invalidFileType: '档案格式错误',
    length6To12: '字符串必须为 6 至 12 字',
    levelUpCreditMoreThan: '升级金额必须大于',
    logoutPlayerSuccess: '用户登出成功',
    maxGreaterThanMin: '最大值必须大于最小值',
    memberNotInSite: '会员不存在于此站点',
    multipleQuerySeparatedBySpace: '使用空格来区分多个搜索条件',
    noAvailablePrivilege: '当前无可用的优惠',
    passwordLength: '密码必须多于 6 字并少于 12 字',
    paymentUpdate: '是否需要复制？如无需复制请按开始',
    paySuccess: '支付成功',
    promoDistributionSuccess: '发放奖金成功',
    reenterPassword: '请重新输入密码',
    registerSuccess: '注册成功',
    replySuccess: '回复成功',
    removePreviousLevel: '请移除上级',
    removeRemarkSuccess: '删除备注成功',
    selectSiteFirst: '请先选择站点',
    selectSiteAndGameTypeFirst: '请先选择站点和游戏类型',
    selectPreviousLevel: '请选择上级',
    selectPaymentType: '请输入支付类型',
    selectMobilePayment: '请先选择手机支付类型',
    selectWebPayment: '请先选择网页支付类型',
    settlementToChecking: '成功更新至待审核',
    settlementToPay: '成功更新至待结算',
    settled: '成功结算',
    summarySuccess: '已选择时段的汇总即将生成，请稍等',
    transferSuccess: '转账成功',
    twoPasswordNotMatch: '重新输入密码不相同',
    unlockMemberSuccess: '成功解锁账号',
    unlockUserSuccess: '成功解锁用户',
    updateCommissionModelSuccess: '更新分红模式成功',
    updateCommissionRateSuccess: '更新分红比率成功',
    updateFinancialLevelSuccess: '更新会员组别成功',
    updatePasswordSuccess: '更新密码成功',
    updatePayment: '提交付款',
    updateProceed: '更新节点以进行付款编辑',
    updateRiskLevelSuccess: '更新风险等级成功',
    updateSuccess: '更新成功',
    updateTimeTypeSuccess: '更新时间类型成功',
    updateToApplySuccess: '成功更新至申请中状态',
    updateToBeforePaidSuccess: '成功更新至待支付状态',
    updateToFailSuccess: '成功更新至失败状态',
    updateToPaySuccess: '成功更新至支付中状态',
    updateToSuspendSuccess: '成功更新至中止状态',
    updateToUnderReviewSuccess: '成功更新至审核中状态',
    updateUserTypeSuccess: '更新账号类型成功',
    updateVIPLevelSuccess: '更新VIP等级成功',
    useCommaToSeparateDomain: '如要加入多个域名, 请使用"," 来分开',
    validateActionRequired: '动作必填',
    validateActivePlayerRequired: '活跃玩家必填',
    validateAdsTypeRequired: '广告类型必填',
    validateAdjustAmountRequired: '平账金额必填',
    validateAdjustReasonRequired: '平账原因必填',
    validateAdjustTypeRequired: '平账类型必填',
    validateAffiliateCodeRequired: '代理代码必填',
    validateAffiliateLevelRequired: '代理级别必填',
    validateAffiliateNameRequired: '代理名必填',
    validateAlphaNumericOnly: '请输入字母和数字',
    validateAmountRequired: '金额必填',
    validateAnnouncementTypeRequired: '公告类型必填',
    validateAtLeastSixChar: '请输入至少6个字符',
    validateBankRequired: '银行必填',
    validateBankCardNumberRequired: '银行卡号码必填',
    validateBankCodeRequired: '银行代码必填',
    validateBankNameRequired: '银行名必填',
    validateBankTypeRequired: '银行类型必填',
    validateBeanNameRequired: '对象名称必填',
    validateBonusAmountRequired: '奖金数额必填',
    validateBonusAmountRatioRequired: '奖金数额比率必填',
    validateBonusTypeRequired: '奖金类型必填',
    validateCardAccountRequired: '银行卡账号必填',
    validateCardAddressRequired: '银行卡地址必填',
    validateCardNumberRequired: '银行卡号码必填',
    validateCategoryRequired: '类别必填',
    validateCauseRequired: '原因必填',
    validateClassNameRequired: '类别名必填',
    validateCodeRequired: '代码必填',
    validateColorRequired: '颜色必填',
    validateCommissionRequired: '分红必填',
    validateCommissionFormat: '分红必须介于 0 至 1 之间',
    validateConfirmPasswordRequired: '确认密码必填',
    validateConfirmPasswordSize: '确认密码必须在 6-12 个字之间',
    validateContentRequired: '内容必填',
    validateCopyFinancialLevel: '请选择至少一个会员组别来复制',
    validateCountryImageRequired: '国家图像必填',
    validateCronExpressionFormat: 'Cron表达式格式错误',
    validateCronExpressionRequired: 'Cron表达式必填',
    validateCurrencyRequired: '货币必填',
    validateCurrencyCodeRequired: '货币代码必填',
    validateCurrencyNameRequired: '货币名称必填',
    validateCustomerSupportAddressFormat: '客服地址JSON字符串格式错误',
    validateDayWithdrawCountRequired: '每日提款次数必填',
    validateDepositExchangeRateRequired: '存款兑换率必填',
    validateDesktopImageRequired: '桌面图像必填',
    validateDesktopBannerRequired: '桌面广告图必填',
    validateDeviceRequired: '设备必填',
    validateDomainRequired: '域名必填',
    validateEmailRequired: '邮箱必填',
    validateEmailFormat: '请输入正确的邮箱格式',
    validateEndDateRequired: '结束日期必填',
    validateEndTimeRequired: '结束时间必填',
    validateExchangeRateRequired: '兑换率必填',
    validateFailReasonRequired: '失败原因必填',
    validateFeeRateRequired: '收费率必填',
    validateFreezeTypeRequired: '冻结类型必填',
    validateFinancialLevelRequired: '会员组别必填',
    validateFrequencyRequired: '频率必填',
    validateGameCodeRequired: '游戏代码必填',
    validateGameNameRequired: '游戏名称必填',
    validateGameTypeRequired: '游戏类型必填',
    validateIconRequired: '图标必填',
    validateIdentifyCodeRequired: '识别码必填',
    validateIpRequired: 'IP 必填',
    validateJobNameRequired: '任务名称必填',
    validateLabelRequired: '标签必填',
    validateLevelRequired: '级别必填',
    validateLevelNameRequired: '级别名称必填',
    validateLevelUpCreditRequired: '升级金额必填',
    validateLoginNameRequired: '登录名必填',
    validateLoginNameSize: '登录名必须在 6-12 个字之间',
    validateMallCodeRequired: '商户号必填',
    validateMallKeyRequired: '秘钥必填',
    validateMallNameRequired: '支付编码必填',
    validateMaxDailyWithdrawRequired: '每日最高提款金额必填',
    validateMaxDailyWithdrawNumber: '每日最高提款金额不能少过或等于0',
    validateMaxDailyWithdrawGreater: '每日最高提款金额必须多过最高提款金额',
    validateMaxDailyWithdrawTimesRequired: '每日最多提款次数必填',
    validateMaxDailyWithdrawTimesNumber: '每日最多提款次数不能少过或等于0',
    validateMaxDepositRequired: '最高存款必填',
    validateMaxDepositGreater: '最高存款必须多过最低存款',
    validateMaxWithdrawAmountRequired: '最高提款金额必填',
    validateMaxWithdrawAmountNumber: '最高提款金额不能少过或等于0',
    validateMaxWithdrawAmountGreater: '最高提款金额必须多过最低提款金额',
    validateMemberTypeRequired: '用户类型必填',
    validateMenuTitleRequired: '菜单标题必填',
    validateMinDepositRequired: '最低存款必填',
    validateMinDepositLesser: '最低存款金额必须少过最高存款金额',
    validateMinWithdrawRequired: '最低提款金额必填',
    validateMinWithdrawNumber: '最低提款金额不能少过或等于0',
    validateMinWithdrawLesser: '最低提款金额必须少过最高提款金额',
    validateMobileImageRequired: '手机图像必填',
    validateMobileBannerRequired: '手机广告图必填',
    validateMobileMessageRequired: '手机提示信息必填',
    validateNameRequired: '名称必填',
    validateNeedRegisterRequired: '需要注册必填',
    validateNetProfitRequired: '净利润必填',
    validateNextActivationTimeRequired: '下次启动时间必填',
    validateNextGetBetEndTime: '下次取投注记录结束时间必填',
    validateNextGetBetPage: '下次取投注记录页面必填',
    validateNextGetBetStartTime: '下次取投注记录开始时间必填',
    validateNoNullValue: '所有的值不能为空',
    validateNumberOnly: '请只输入数字',
    validateNumberFourDecimalOnly: '请只输入大于0并最多4位小数的数字',
    validateOnlyThreeChar: '请输入3个字符',
    validateParamRequired: '参数必填',
    validatePasswordRequired: '密码必填',
    validatePasswordSize: '密码必须在 6-12 个字之间',
    validatePaymentNameRequired: '支付名称必填',
    validatePaymentTypeRequired: '支付类型必填',
    validatePayResultTypeRequired: '支付结果显示方式必填',
    validatePayTypeRequired: '支付类型必填',
    validatePayTypeCodeRequired: '支付类型代码必填',
    validatePayTypeNameRequired: '支付类型名称必填',
    validatePayTypeNameCodeDifferent: '支付类型名称与支付类型代码不能为相同值',
    validatePlatformRequired: '平台必填',
    validatePlatformAccountRequired: '平台账号必填',
    validatePlatformCodeRequired: '平台代码必填',
    validatePlatformNameRequired: '平台名称必填',
    validatePrivilegeRequired: '优惠名必填',
    validatePromoCodeRequired: '优惠代码必填',
    validatePromoTypeRequired: '优惠类型必填',
    validateQueryNumberRequired: '搜索数量必填',
    validateQueryRestrictionRequired: '搜索限制必填',
    validateRateRequired: '比率必填',
    validateRealNameRequired: '真实名字必填',
    validateReasonRequired: '原因必填',
    validateReasonTypeRequired: '原因类型必填',
    validateRebatePercentageRequired: '返水比例必填',
    validateMaxRebateRequired: '最大返水额必填',
    validateRecordTimeRequired: '记录时间必填',
    validateRedirectRequired: '域名转址必填',
    validateReenterPasswordRequired: '请重新输入密码',
    validateRemarkRequired: '备注必填',
    validateRequestUrlRequired: '回调地址必填',
    validateResultDateRequired: '开彩日期必填',
    validateResultNumber: '开彩三位数号码必填',
    validateRebateFormat: '返点必须介于 0 至 1 之间',
    validateRevenueShareFormat: '收入必须介于 0 至 1 之间',
    validateRewardGroupRequired: '奖金组必填',
    validateRewardGroupNameRequired: '奖金组名称必填',
    validateRewardGroupDownlineQuotaRequired: '最高下线人数必填',
    validateRewardGroupDownlineQuotaFormat: '最高下线人数必须大于0',
    validateRiskLevelRequired: '风险等级必填',
    validateRoleRequired: '角色必填',
    validateRoleNameRequired: '角色名称必填',
    validateRolloverRequired: '倍数必填',
    validateSchemaNameRequired: '模式名必填',
    validateSequenceRequired: '排序必填',
    validateSiteRequired: '站点必填',
    validateSiteCodeRequired: '站点代码必填',
    validateStartTimeRequired: '开始时间必填',
    validateStateRequired: '状态必填',
    validateStatusRequired: '状态必填',
    validateStopAfterFailureRequired: '失败后停止必填',
    validateSuccess: '验证成功',
    validateSupportTypeRequired: '支持类型必填',
    validateTeamNameEnRequired: '团队英文名称必填',
    validateTeamNameLocalRequired: '团队当地名称必填',
    validateTelephoneRequired: '电话号码必填',
    validateThirdSerialNumberRequired: '第三方流水号必填',
    validateThreeDigitsNumbeRequired: '三位数字必填',
    validateTimeTypeRequired: '时间类型必填',
    validateTitleRequired: '标题必填',
    validateTriggerTypeRequired: '触发类型必填',
    validateUrlFormat: '请输入正确的域名地址',
    validateUsernameRequired: '用户名必填',
    validateUsernameSize: '用户名必须在 6-12 个字之间',
    validateUserTypeRequired: '用户类型必填',
    validateValueRequired: '数值必填',
    validateVIPRequired: 'VIP必填',
    validateWalletTypeRequired: '钱包类型必填',
    validateWayRequired: '设备必填',
    validateWebMessageRequired: '网页提示信息必填',
    validateWholeNumberOnly: '请输入整数',
    validateWithdrawExchangeRateRequired: '提款兑换率必填',
    validateWithdrawPlatformNameRequired: '提款平台名称必填',
    validateTigerCardPeriodRequired: '期数必填',
    validateTigerCardTimeRequired: '抽卡时间必填',
    validateTigerCardBonusTimeRequired: '发奖时间必填',
    validateTigerSumAwardRequired: '总奖金必填',
    validateVirtualCountRequired: '数量必填',
    validateHostRequired: '域名必填',
    validatePortRequired: '端口必填',
    validateSignNameRequired: '公司名字必填',
    validateSecretIdRequired: '密码必填',
    validateSecretKeyRequired: '密钥必填',
    validateAppIdRequired: '应用ID必填',
    validateTemplateIdRequired: '模板ID必填',
  },
  menu: {
    undefined: '',
    Dashboard: '仪表盘',
    Member: '会员',
    'Member List': '会员列表',
    'Member Details': '会员信息',
    'Member Edit Log': '会员编辑日志',
    'Member Bank Card History': '会员绑卡记录',
    'Member Freeze Record': '会员账号冻结记录',
    'Member Amount Adjust': '会员平账记录',
    'Member Privilege Blacklist': '会员优惠黑名单',
    'Member Sms': '发送短信记录',
    'Import Bet Records': '导入投注记录',
    'Member Transfer Record': '会员转账记录',
    'Member VIP': '会员等级列表',
    Affiliate: '代理',
    'Affiliate Summary': '代理总结',
    'Affiliate Monthly Settlement': '代理结算记录',
    'Affiliate Monthly Settlement Detail': '代理结算信息',
    'Affiliate Settlement Checking': '代理待审核记录',
    'Affiliate Settlement Payment': '代理待结算记录',
    'Affiliate Domain Management': '代理域名管理',
    'Affiliate List': '代理列表',
    'Affiliate Details': '代理信息',
    'Site Affiliate Commission': '站点代理分红',
    'Deposit Management': '存款管理',
    'Online Deposit': '线上存款',
    'Deposit Record': '存款记录',
    'Withdrawal Management': '提款管理',
    'Withdrawal Process': '提款流程',
    'Withdrawal Affiliate Process': '代理提款流程',
    'Withdrawal Record': '提款记录',
    'Withdrawal Affiliate Record': '代理提款记录',
    'Withdrawal Platform Balance': '提款平台余额',
    'Withdraw Review': '提款复查',
    'Withdrawal Bank Blacklist': '提款卡黑名单',
    'Withdraw Tips Setting': '提款提示设定',
    'Reward Group': '奖金组',
    Applying: '申请中',
    'Affiliate Applying': '申请中',
    'Affiliate Under review': '审核中',
    'To be affiliate paid': '待支付',
    'Affiliate Payment on going': '支付中',
    'Crypto Affiliate Payment on going': '数字币支付中',
    'Ewallt Affiliate Payment on going': '电子钱包支付中',
    'Alipay Affiliate Payment on going': '支付宝支付中',
    'Affiliate Automatic Payment': '自动支付',
    'Affiliate Suspend': '中止',
    'Under review': '审核中',
    'To be paid': '待支付',
    'Payment on going': '支付中',
    'Crypto Payment on going': '数字币支付中',
    'Ewallt Payment on going': '电子钱包支付中',
    'Alipay Payment on going': '支付宝支付中',
    'Automatic Payment': '自动支付',
    Suspend: '暂不处理',
    Promotion: '优惠',
    'Privilege Info': '优惠列表',
    'Home Banner': '首页广告',
    'Promotion pages': '优惠页面',
    'Edit Promo': '编辑优惠页面',
    'Team Votes': '团队投票',
    'Team Votes Records': '团队投票记录',
    'Team Votes Settings': '团队投票设置',
    'Tiger Card': '虎卡',
    'Telephone Numbers': '电话号码',
    'Site Email': '邮件地址',
    'Ads Popout': '弹窗管理',
    'Add Ads Popout': '新建弹窗',
    'Edit Ads Popout': '编辑弹窗',
    Lottery: '彩票',
    'Lottery Record List': '彩票记录',
    'Lottery Result List': '彩票结果',
    'Site Management': '站点管理',
    Message: '信息',
    Announcement: '公告',
    'Announcement Type': '公告类型',
    'VIP Rebate': 'VIP 返水',
    VIP: 'VIP',
    Game: '游戏',
    'Financial Level': '会员组别',
    'Risk Level': '风险等级',
    'Ip Label': 'IP 标签',
    'Site Platform Management': '平台站点管理',
    Permission: '权限',
    Menu: '菜单',
    Roles: '角色',
    User: '用户',
    Report: '报表',
    'Privilege Record': '优惠记录',
    'Summary Report': '总结报表',
    'Privilege Report': '优惠报表',
    'Deposit Report': '存款报表',
    'Withdraw Report': '提款报表',
    'Finance Report': '财务报表',
    'Affiliate Report': '代理报表',
    'Site Member Report': '会员报表',
    System: '系统',
    'Operator Log': '后台日志',
    'Member Platform': '用户平台设置',
    Site: '站点',
    Config: '设置',
    Currency: '币别',
    Platform: '平台',
    'Site Platform': '平台站点',
    Schedule: '定时器',
    'Platform Account': '平台账号',
    'Data Dictionary': '数据字典',
    'Manual Summary': '手动汇总',
    'Affiliate Manual Summary': '手动代理汇总',
    'Payment Management': '支付管理',
    'Bank Info': '银行信息',
    'Bank Card': '银行卡',
    'Payment Type': '支付类型',
    'Payment Display List': '支付平台显示',
    'Platform Game Report': '场馆游戏报表',
    Payment: '支付',
    'Add Payment': '新增支付',
    'Edit Payment': '编辑支付',
    CurrencyRate: '汇率',
    'Payment Display': '支付显示',
    'Withdraw Platform': '提款平台',
    'Withdraw Setting': '提款设置',
    'Site Withdraw Platform': '提款平台站点',
    'Deposit Setting': '存款设置',
    'Withdraw Display': '提款显示',
    'Payment Bank Management': '支付银行管理',
    'Withdraw Platform Bank Management': '提款平台银行管理',
    'Finance Feedback': '存提催单',
    'Summary Register Report': '注册总结报表',
    'Summary Fdp Report': 'Fdp总结报表',
    'Summary Deposit Report': '存款总结报表',
    'Summary Withdraw Report': '提款总结报表',
    'Summary Active Report': '活跃会员总结报表',
    'Summary Member Report': '日会员总结报表',
    'Email Setting': '邮件设置',
    'SMS Setting': '短信设置',
  },
  error: {
    400: '请求无效',
    401: '用户未授权',
    403: '禁止访问',
    404: '找不到网页',
    405: '方法不被允许',
    500: '系统错误',
    501: '请求ID重复',
    502: '无法找到伺服器',
    504: '请求太频繁',
    601: '令牌验证失败',
    602: '令牌已逾期',
    603: '无法找到令牌',
    604: '账号已登录',
    605: '账号已登出',
    606: '所选角色已被分配至用户，请先将此角色取消分配再尝试',
    607: '当用户类别不是站点管理员，站点不能为空',
    700: '新密码不能与旧密码相同',
    701: '参数已存在',
    707: '余额不足',
    800: '验证码失败',
    801: '验证码已失效',
    900: '无法找到会员信息',
    901: '无法找到此代理信息',
    1000: '登录名或密码错误',
    1001: '登录失败, 账号已被禁用',
    1005: '登录失败, 账号已被封锁, 请于明日再尝试',
    1006: '登录失败, 账号不存在',
    1007: '该提款订单已经提交, 请于20分钟后在尝试',
    9000: '创建定时任务失败',
    9001: '更新定时任务失败',
    9002: '删除定时任务失败',
    10009: '请输入至少一个搜索条件',
    10010: '公告类型名称已存在',
    10011: '公告标题已存在',
    10012: '公告类型不存在',
    10013: '此公告类型已被现有公告使用, 请先删除公告',
    10020: '广告标题已存在',
    10021: '无法找到此广告',
    10030: '优惠名称已存在',
    10031: '无法找到此优惠',
    10032: '图像档案格式错误',
    10033: '广告图像上传失败',
    10034: '优惠图像上传失败',
    10040: '风险等级名称已存在',
    10041: '风险等级颜色已存在',
    10050: 'IP标签已存在',
    10051: 'IP标签不存在',
    11000: '代理不存在',
    11006: '站点代理分红已存在',
    11007: '站点代理分红不存在',
    11008: '代理信息不存在',
    14001: '手机号码已经存在',
    14005: '代理分红必须少于上级代理分红',
    11009: '代理分红必须多于下级代理分红',
    12000: '优惠名称已存在',
    12001: '优惠代码已存在',
    12010: '货币名称已存在',
    12011: '此站点已设置汇率',
    12020: '此记录状态并不是 SENDING',
    12030: '站点名称已存在',
    12031: '站点代码已存在',
    12032: '此站点为母站点',
    12033: '母站点不可更改',
    12034: '支付名称已存在',
    12035: '支付代码已存在',
    12036: '优惠不存在',
    12037: '支付ID不存在',
    12038: '金额不可多于最高奖金',
    12039: '会员已被类入此优惠的黑名单',
    12040: '支付名称不存在',
    12041: '支付编码已存在',
    12042: '商户号已存在',
    12050: '银行不存在',
    12051: '银行名称已存在',
    12052: '银行代码已存在',
    12053: '银行卡识别码已存在',
    12054: '银行卡已存在',
    14110: '平台已存在于此站点',
    14111: '状态必须为 CLOSE 为期一个月方能删除',
    14112: '此平台站点的状态为 CLOSE 的时间少于一个月, 请于一个月后再删除',
    14120: '此会员组别, 币别, 站点和设备设置已存在',
    14121: '最低提款金额不能少于0',
    14122: '最高提款金额不能少于0',
    14123: '最高提款次数不能少于0',
    14124: '每日最高提款金额不能少于0',
    14125: '最低提款金额不能多于最高金额',
    14126: '最高提款金额不能少于最低金额',
    14127: '每日最高提款金额不能少于最高提款金额',
    14130: '平台账号不存在',
    14131: '平台账号已用于其他平台站点',
    15000: 'VIP名称已存在于此站点',
    15001: 'VIP不存在',
    15002: 'VIP已被现有VIP使用, 故无法删除',
    15003: 'VIP返水规则已存在于此VIP级别和游戏类型',
    15004: 'VIP返水规则不存在',
    15010: '会员组别名称已存在于此站点',
    15012: '会员组别不存在',
    15020: '存款记录不存在',
    15021: '此会员组别, 支付类型, 站点和设备设置已存在',
    15030: '无法处理该 JSON 数据',
    15040: '提款显示名称已存在',
    15041: '提款显示不存在',
    15050: '支付银行已存在',
    15051: '支付代码已存在',
    15060: '提款平台不存在',
    15061: '提款平台银行已存在',
    15062: '提款平台代码已存在',
    15063: '提款记录不存在',
    15064: '提款平台不存在',
    15065: '提款卡不存在',
    16000: '开彩三位数号码必填',
    16001: '开彩号码必须为三位数',
    16002: '该日期已有开彩结果',
    16010: '团队英文名称已存在',
    16011: '团队不存在',
    17010: '团队投票记录不存在',
    17011: '团队投票记录已被取消',
    18000: '域名已存在',
    18001: '代理域名不存在',
    19000: '此提款银行卡已被列入黑名单',
    19101: '广告标题已存在',
    19102: '广告不存在',
    19103: '此站点的广告已经打开，请先关闭当前打开的广告',
    20000: '短信设置站点已存在',
    20100: '邮件设置站点已存在',
    20201: '错误的类型',
    20202: '请输入 ‘正常’ / ‘冻结’ ',
    20203: '生日格式错误',
    20204: '找不到风控等级，请输入正确的风控等级(例：1)',
    20205: '找不到财务等级，请输入正确的财务等级(例：1)',
    20206: '请输入 ’测试‘ / ’普通‘ / ’三方‘ / ’代理‘',
  },
}
