export const ResponseCode = {
  SUCCESS: 0,
  ERROR_SYSTEM: 500,
  ERROR_BAD_REQUEST: 400,
  ERROR_UNAUTHORIZED: 401,
  ERROR_FORBIDDEN: 403,
  ERROR_NOT_FOUND: 404,
  ERROR_METHOD_NOT_SUPPORT: 405,
  ERROR_LOGIN_INVALID_SESSION: 501,
  ERROR_LOGIN_EXPIRED_SESSION: 502,
  ERROR_PASS_REPEAT: 600,
  ERROR_NAME_EXIST: 601,
}
